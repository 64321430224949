// TODO: check date format, may not display correctly in safari
export default [
  {
    id: 1,
    name: 'Нескучный июль',
    description: 'Веселые и познавательные ивенты для молодежи',
    slug: '',
    previewImage: '/afisha/compilation/1.png',
    dateStart: 1688169600000,
    dateEnd: 1690761600000,
    eventIds: [31, 32, 33, 34, 35, 36],
    events: [
      {
        id: 1,
        name: 'Проверить свою эрудицию и познакомиться с новыми людьми',
        description: 'Квиз — это возможность для старшеклассников интересно и с пользой провести досуг, научиться работать в команде и пополнить багаж знаний. Несколько команд будут состязаться друг с другом, отвечая на вопросы в течение заданного времени.',
        eventIds: [31, 32],
      },
      {
        id: 2,
        name: 'Научиться актерскому мастерству',
        description: 'Программа обучения включает в себя уроки по актерскому мастерству, способствующие раскрытию творческого потенциала, снятию «зажимов» и страхов выступать на широкую публику.',
        eventIds: [33],
      },
      {
        id: 3,
        name: 'Почувствовать себя детективом и найти предателя среди своих',
        description: 'Детективно-ролевая игра по мотивам компьютерной игры «Among Us», где участники должны рассекретить предателя в рядах своей команды на борту космического корабля, пока он всех тайно не уничтожил.',
        eventIds: [34],
      },
      {
        id: 4,
        name: 'Окунуться в атмосферу общежития на один день',
        description: '«Арт-общага» — это пространство для творческой и талантливой молодежи, здесь каждый найдет себе нужную комнату',
        eventIds: [35],
      },
      {
        id: 5,
        name: 'Вернуться в прошлое и раскрыть тайну старинного дома',
        description: 'Участникам предстоит выполнить задания и узнать, какую тайну хранит в себе старинный дом. На каждом этапе их ждут интересные испытания.',
        eventIds: [36],
      },
    ],
  },
  {
    id: 2,
    name: 'Нескучный июль',
    description: 'Веселые и познавательные ивенты для молодежи',
    slug: '',
    previewImage: '/afisha/compilation/1.png',
    dateStart: 1628427600000,
    dateEnd: 1628427600000,
    eventIds: [31, 32, 33, 34, 35, 36],
    events: [
      {
        id: 1,
        name: 'Проверить свою эрудицию и познакомиться с новыми людьми',
        description: 'Квиз — это возможность для старшеклассников интересно и с пользой провести досуг, научиться работать в команде и пополнить багаж знаний. Несколько команд будут состязаться друг с другом, отвечая на вопросы в течение заданного времени.',
        eventIds: [31, 32],
      },
      {
        id: 2,
        name: 'Научиться актерскому мастерству',
        description: 'Программа обучения включает в себя уроки по актерскому мастерству, способствующие раскрытию творческого потенциала, снятию «зажимов» и страхов выступать на широкую публику.',
        eventIds: [33],
      },
      {
        id: 3,
        name: 'Почувствовать себя детективом и найти предателя среди своих',
        description: 'Детективно-ролевая игра по мотивам компьютерной игры «Among Us», где участники должны рассекретить предателя в рядах своей команды на борту космического корабля, пока он всех тайно не уничтожил.',
        eventIds: [34],
      },
      {
        id: 4,
        name: 'Окунуться в атмосферу общежития на один день',
        description: '«Арт-общага» — это пространство для творческой и талантливой молодежи, здесь каждый найдет себе нужную комнату',
        eventIds: [35],
      },
      {
        id: 5,
        name: 'Вернуться в прошлое и раскрыть тайну старинного дома',
        description: 'Участникам предстоит выполнить задания и узнать, какую тайну хранит в себе старинный дом. На каждом этапе их ждут интересные испытания.',
        eventIds: [36],
      },
    ],
  },
];
