export default [
  {
    id: 1,
    images: [
      'products/1.png',
      'products/2.jpg',
      'products/3.png',
      'products/4.png',
      'products/5.png',
      'products/2.jpg',
      'products/3.png',
      'products/4.png',
      'products/5.png',
      'products/2.jpg',
      'products/3.png',
      'products/4.png',
      'products/5.png',
    ],
    name: 'Худи «Ледокол», цвет сиреневый, размер XL ',
    description: 'Худи "Ледокол" в сиреневом цвете и размере XL - идеальный выбор для стильного и комфортного образа. Эта модель выполнена из высококачественного материала, который обеспечивает мягкость и приятное ощущение на коже. Стильный дизайн с логотипом "Ледокол" добавляет особую привлекательность этой одежде. Благодаря своему размеру XL, худи подойдет для любого типа фигуры, обеспечивая свободу движений. Независимо от того, идете ли вы на прогулку или занимаетесь спортом, худи "Ледокол" станет надежным и стильным спутником в любых ситуациях.',
    amount: 0,
    price: 2600,
    type: 1,
    attributes: [
      { id: 1, name: 'Цвет', value: 'Сиреневый' },
      { id: 2, name: 'Размер', value: 'XL(52)' },
      { id: 3, name: 'Материал', value: 'Хлопок 100%' },
      { id: 4, name: 'Цвет', value: 'Сиреневый' },
      { id: 5, name: 'Размер', value: 'XL(52)' },
      { id: 6, name: 'Материал', value: 'Хлопок 100%' },
    ],
    partner: {
      id: 1,
      name: 'ООО «тоска #008000»',
      links: [
        {
          name: 'vkontakte-social',
          url: 'https://vk.com/zhivemnasevere',
          title: 'ВКонтакте',
        },
        {
          name: 'telegram-social',
          url: 'https://t.me/zhivem_na_severe',
          title: 'Telegram',
        },
      ],
    },
  },
  {
    id: 2,
    images: ['products/2.jpg'],
    name: 'Футболка "Береги Ямал"',
    amount: 4,
    price: 600,
    type: 1,
  },
  {
    id: 3,
    images: ['products/3.png'],
    name: 'Худи «Ледокол», цвет желтый, размер XL ',
    amount: 1,
    price: 3600,
    type: 1,
  },
  {
    id: 4,
    name: 'Скидка 5% на покупку абонемента в студию "Атмосфера"',
    amount: 2,
    price: 600,
    type: 2,
    description: `Студия “Атмосфера” - это место, где вы можете найти все необходимое для вашего физического и эмоционального благополучия. Наши тренеры и инструкторы помогут вам достичь ваших целей и развить свои навыки в различных направлениях фитнеса.

    Мы предлагаем широкий выбор программ, включая йогу, пилатес, стретчинг, силовые тренировки, кардио-тренировки, функциональные тренировки и многое другое. Все наши тренеры имеют высшее спортивное образование и многолетний опыт работы в фитнес-индустрии.
    
    Кроме того, мы предлагаем индивидуальные тренировки и консультации по питанию, чтобы помочь вам достичь максимальной эффективности и результатов. Наша студия также предлагает групповые занятия, которые проводятся в удобное для вас время и в удобном месте.
    
    Не упустите возможность воспользоваться скидкой 5% и начать свой путь к здоровому образу жизни уже сегодня! Запишитесь на пробное занятие прямо сейчас и получите возможность попробовать наши программы лично.`,
    partner: {
      id: 1,
      name: 'ООО «тоска #008000»',
      links: [
        {
          name: 'vkontakte-social',
          url: 'https://vk.com/zhivemnasevere',
          title: 'ВКонтакте',
        },
        {
          name: 'telegram-social',
          url: 'https://t.me/zhivem_na_severe',
          title: 'Telegram',
        },
        {
          name: 'odnoklassniki-social',
          url: 'https://ok.ru/group/53447323877518',
          title: 'Odnoklassnkiki',
        },
        {
          name: 'tik-tok-social',
          url: 'https://www.tiktok.com/@zhivem_na_severe',
          title: 'Tik-Tok',
        },
      ],
    },
  },
  {
    id: 5,
    images: ['products/4.png'],
    name: 'Худи «Ледокол», цвет сиреневый, размер XL ',
    amount: 10,
    price: 1000,
    type: 1,
  },
  {
    id: 6,
    images: ['products/5.png'],
    name: 'Худи «Ледокол», цвет желтый, размер XL ',
    amount: 8,
    price: 2700,
    type: 1,
  },
  {
    id: 7,
    images: ['products/1.png'],
    name: 'Худи «Ледокол», цвет сиреневый, размер XL ',
    amount: 2,
    price: 10600,
    type: 1,
  },
  {
    id: 8,
    images: ['products/5.png'],
    name: 'Худи «Ледокол», цвет сиреневый, размер XL ',
    amount: 222,
    price: 234600,
    type: 1,
  },
  {
    id: 9,
    images: ['products/3.png'],
    name: 'Худи «Ледокол», цвет сиреневый, размер XL ',
    amount: 2,
    price: 10600,
    type: 1,
  },
  {
    id: 10,
    images: ['products/4.png'],
    name: 'Худи «Ледокол», цвет сиреневый, размер XL ',
    amount: 2,
    price: 8600,
    type: 1,
  },
];
