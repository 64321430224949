// TODO: check date format, may not display correctly in safari
export default [
  {
    id: 1,
    name: 'Концерт Юлии Чичериной',
    ageRestriction: 6,
    description: '<p><span>Юлия Чичерина — российская рок-певица и музыкант, создатель собственной группы, которая получила известность в нулевых годах. Песни исполнительницы становились хитами, а сама она неоднократно награждалась престижными премиями. Песни артистки постоянно становились саундтреками самых нашумевших картин российского кинематографа. Результат богатого творческого пути во многом выражается на живых выступлениях музыканта. На концерте прозвучат песни «Ту-лу-ла», «Жара», «Мой рок-н-ролл» и абсолютно новые композиции.</span></p>',
    shortDescription: 'Юлия Чичерина — российская рок-певица и музыкант, создатель собственной группы, которая получила известность в нулевых годах.',
    image: {
      name: 'afisha/yulia_chicherina.webp',
      realName: 'yulia_chicherina.webp',
      author: 'МИТРОФАНОВА МАРГАРИТА',
      source: 'HTTPS://YANDEX.RU/IMAGES/SEARCH?TEXT=LIVEJOURNAL.COM',
    },
    tags: [
      {
        id: 158,
        name: 'Классическая музыка',
        sysName: 'klassicheskaya-muzyka',
      },
    ],
    category: {
      name: 'Концерт',
      sysName: 'concert',
    },
    isFree: false,
    price: 750,
    maxPrice: 600,
    saleLink: 'https://www.culture.ru/events/3120904/koncert-yulii-chicherinoi?location=yamalo-neneckiy-avtonomnyy-okrug',
    extraFields: {},
    places: [
      {
        id: 1,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-08-08T16:00:00',
            endLocal: '2021-08-08T17:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Салехард',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Арктическая',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '1',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Культурно-деловой центр г. Салехарда',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Культурно-деловой центр г. Салехарда',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Ямало-Ненецкий АО',
          type: 'АО',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Салехард',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Арктическая',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '1',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Городские Зрелищные Кассы',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: false,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628427600000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 2,
    name: 'Выставка «Оттаявший мир»',
    ageRestriction: 6,
    description: '<p><span>«Оттаявший мир» — новое название выставки «Время мамонта», где «прописан» всемирно известный экспонат мамонтенок Люба, Монгоченский мамонт, шерстистый носорог и другие представители ископаемой фауны и флоры. Теперь здесь появился еще один видный экспонат — гости МВК имени И.С. Шемановского впервые увидят кости мамонта Тадибе, найденного в 2020 году в Сеяхинской тундре. Однако это еще не все сюрпризы и новинки — расширится и смысловое наполнение выставки.<br/><br/>Актуальная экспозиция представлена в новом современном дизайне. К примеру, на выставке создан визуальный эффект «оттаивания» мамонтов, расширен мультимедийный и интерактивный контент: для удобства посетителей здесь появились дополнительные видеоэкраны и сенсорные киоски с информацией об истории экспонатов и эпохе, к которой они относятся.</span></p>',
    shortDescription: '«Оттаявший мир» — новое название выставки «Время мамонта», где «прописан» всемирно известный экспонат мамонтенок Люба, Монгоченский мамонт, шерстистый носорог и другие представители ископаемой фауны и флоры.',
    image: {
      name: 'afisha/exhibition.webp',
      realName: 'exhibition.webp',
      author: 'ПУТРЯЕВ А. И.',
      source: 'АРХИВ ГАУ ЯНАО "ЯМАЛО-НЕНЕЦКИЙ ОКРУЖНОЙ МУЗЕЙНО-ВЫСТАВОЧНЫЙ КОМПЛЕКС ИМЕНИ И. С. ШЕМАНОВСКОГО"',
    },
    tags: [
      {
        id: 158,
        name: 'Выставки',
        sysName: 'exhibition',
      },
    ],
    category: {
      name: 'Выставки',
      sysName: 'exhibitions',
    },
    isFree: false,
    price: 75,
    maxPrice: 1400,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 2,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-08-08T16:00:00',
            endLocal: '2021-08-08T17:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Салехард',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Чубынина',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '38',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Ямало-Ненецкий окружной музейно-выставочный комплекс имени И.С. Шемановского',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Ямало-Ненецкий окружной музейно-выставочный комплекс имени И.С. Шемановского',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Ямало-Ненецкий',
          type: 'АО',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Салехард',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Чубынина',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Городские Зрелищные Кассы',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: false,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628427600000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Выставка «Оттаявший мир»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 3,
    name: 'Показ фильма «Сквозь время»',
    ageRestriction: 6,
    description: 'Итан Блэйк и его брат ведут исследовательскую деятельность. На одном из испытаний происходит нечто необычное: братья оказываются в месте, которого нет ни на одной карте. Загадочный остров обволакивает туман. Герои пока не понимают, что место, которое они нашли, — аномальная зона, где прошлое, настоящее и будущее сливаются друг с другом. Необходимо разгадать загадку острова, пока не случилось необратимое. Но чтобы дойти до сути, нужно сначала пережить все те испытания, которые предлагает братьям таинственная туманность.',
    shortDescription: 'Режиссер — Александр Богуславский.',
    image: {
      name: 'afisha/skvoz-vrem.webp',
      realName: 'skvoz-vrem..webp',
      author: 'АЛЕКСАНДР БОГУСЛАВСКИЙ',
      source: 'https://www.example.com/articles/10/22438',
    },
    tags: [
      {
        id: 158,
        name: 'Классическая музыка',
        sysName: 'klassicheskaya-muzyka',
      },
    ],
    category: {
      name: 'Кино',
      sysName: 'films',
    },
    isFree: false,
    price: 200,
    maxPrice: 1400,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 3,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-08-08T16:00:00',
            endLocal: '2021-08-08T17:00:00',
          },
        ],
        address: {
          region: {
            name: 'Краснодарский',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'край',
            isPostfix: true,
          },
          city: {
            name: 'Краснодар',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Красная',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '55',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: [
      {
        name: 'afisha/afisha-image-1.png',
        realName: 'DSC_0437.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
      {
        name: 'afisha/afisha-image-2.png',
        realName: 'DSC_0481.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
      {
        name: 'afisha/afisha-image-3.png',
        realName: 'DSC_0482.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
      {
        name: 'afisha/afisha-image-1.png',
        realName: 'DSC_0438.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
      {
        name: 'afisha/afisha-image-2.png',
        realName: 'DSC_0483.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
      {
        name: 'afisha/afisha-image-3.png',
        realName: 'DSC_0484.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
      {
        name: 'afisha/afisha-image-1.png',
        realName: 'DSC_0439.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
      {
        name: 'afisha/afisha-image-2.png',
        realName: 'DSC_0485.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
      {
        name: 'afisha/afisha-image-3.png',
        realName: 'DSC_0486.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
    ],
    organization: {
      id: 14562,
      name: 'Городские Зрелищные Кассы',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Городские Зрелищные Кассы',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: false,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 4,
    name: 'Мастер-классы «Арт-пространства»',
    ageRestriction: 6,
    description: '<p><span>Проект Окружного дома ремесел «Твое арт-пространство» — это место для смелых поисков в разных направлениях искусства: папье-маше, мозаика, северный комикс, создание коллажей и брошей, живопись, роспись одежды, флюид-арт, рисование спиртовыми чернилами и многое другое.</span></p>',
    shortDescription: 'Здесь можно пройти обучающие курсы по рисованию или созданию эксклюзивных изделий в стиле хендмейд, посетить творческие встречи с именитыми художниками и скульпторами, послушать лекции и тренинги лучших коучей в сфере культуры и искусства',
    image: {
      name: 'afisha/master-class.webp',
      realName: 'master-class.webp',
      author: 'ГАУК ЯНАО «ОКРУЖНОЙ ДОМ РЕМЕСЕЛ',
      source: 'HTTPS://ODR.YANAO.RU/',
    },
    tags: [
      {
        id: 158,
        name: 'Классическая музыка',
        sysName: 'klassicheskaya-muzyka',
      },
    ],
    category: {
      name: 'Мастер классы',
      sysName: 'learning',
    },
    isFree: false,
    price: 200,
    maxPrice: 800,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 4,
        locale: {
          id: 1449,
          sysName: 'salehard',
          name: 'Салехард',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-08-08T16:00:00',
            endLocal: '2021-08-08T17:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Салехард',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Свердлова',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '24',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: [
      {
        name: 'afisha/afisha-image-1.png',
        realName: 'DSC_0437.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
      {
        name: 'afisha/afisha-image-2.png',
        realName: 'DSC_0481.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
      {
        name: 'afisha/afisha-image-3.png',
        realName: 'DSC_0482.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
      {
        name: 'afisha/afisha-image-1.png',
        realName: 'DSC_0438.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
      {
        name: 'afisha/afisha-image-2.png',
        realName: 'DSC_0483.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
      {
        name: 'afisha/afisha-image-3.png',
        realName: 'DSC_0484.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
      {
        name: 'afisha/afisha-image-1.png',
        realName: 'DSC_0439.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
      {
        name: 'afisha/afisha-image-2.png',
        realName: 'DSC_0485.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
      {
        name: 'afisha/afisha-image-3.png',
        realName: 'DSC_0486.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
    ],
    organization: {
      id: 14562,
      name: 'Городские Зрелищные Кассы',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Городские Зрелищные Кассы',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 5,
    name: 'Показ фильма «Снегирь»',
    ageRestriction: 16,
    description: '<p><span>На сейнере «Снегирь» собралась компания бродяг, у которых общая цель — заработать и не умереть. Восемнадцатилетним студентам Никите и Максиму, которые недавно устроились работать на судно, жизнь в открытом море представлялась куда более захватывающей, но их ожидания сразу же разбиваются о жестокую реальность. О привычной комфортной жизни приходится забыть.</span></p>',
    shortDescription: 'Режиссер — Борис Хлебников.',
    image: {
      name: 'afisha/snegir.webp',
      realName: 'snegir.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 120,
        name: 'Драмма',
        sysName: 'dramma',
      },
    ],
    category: {
      name: 'Кино',
      sysName: 'films',
    },
    isFree: false,
    price: 250,
    maxPrice: 1400,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 5,
        locale: {
          id: 1449,
          sysName: 'пгт. Пангоды',
          name: 'pgt. Pangodi',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-08T16:00:00',
            endLocal: '2021-06-022T17:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'пгт. Пангоды',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Ленина',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '25',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Кинотеатр Viva Cinema',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: [
      {
        name: 'afisha/afisha-image-1.png',
        realName: 'DSC_0437.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
      {
        name: 'afisha/afisha-image-2.png',
        realName: 'DSC_0481.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
    ],
    organization: {
      id: 14562,
      name: 'Кинотеатр Viva Cinema',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Ямало-Ненецкий АО',
          type: 'АО',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Пангоды',
          type: 'пгт',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Ленина',
          type: '25',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Городские Зрелищные Кассы',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Показ фильма «Снегирь»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 6,
    name: 'Показ фильма «Солнце на вкус»',
    ageRestriction: 6,
    description: '<p><span>Тимур отправляется из Москвы в Узбекистан, чтобы провести время со своим чудным дедом. Мальчик исследует новый дом и случайно обнаруживает необычный медальон, который, судя по виду, существует не один век. Тимур еще не знает, что эта находка приведет его к древним сокровищам! Вместе с дедушкой герой отправится в опасное и захватывающее приключение. Зрителей ожидают волшебство, таинственные лабиринты и сложные загадки.</span></p>',
    shortDescription: 'Режиссер — Эльдар Салаватов. В ролях — Леонид Басов, Александр Ильин, Камила Якубова, Андрей Мерзликин.',
    image: {
      name: 'afisha/solnce.webp',
      realName: 'solnce.webp',
      author: 'ЭЛЬДАР САЛАВАТОВ,',
      source: 'https://www.example.com/articles/10/22438',
    },
    tags: [
      {
        id: 158,
        name: 'Классическое искусство',
        sysName: 'klassicheskoe-iskusstvo',
      },
    ],
    category: {
      name: 'Кино',
      sysName: 'kino',
    },
    isFree: false,
    price: 200,
    maxPrice: 1400,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 4,
        locale: {
          id: 1449,
          sysName: 'noviy urengoy',
          name: 'Новый Уренгой,',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-08-08T16:00:00',
            endLocal: '2021-08-08T17:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой,',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Кинотеатры',
          sysName: 'kinoteatri',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Кинотеатр Сезар',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: [
      {
        name: 'afisha/afisha-image-1.png',
        realName: 'DSC_0437.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
    ],
    organization: {
      id: 14562,
      name: 'Кинотеатр Сезар',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Ямало-Ненецкий АО',
          type: 'АО',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Новый Уренгой',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Сибирская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '26',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Новый Уренгой',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Кинотеатр Сезар',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: false,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 7,
    name: 'Показ фильма «Вызов»',
    ageRestriction: 12,
    description: '<p><span>Первый фильм, снятый в открытом космосе. На Международной космической станции космонавту внезапно стало плохо. Необходимо срочно провести операцию на сердце, но как это сделать в таких непростых условиях? Торакальный хирург Евгения готова пройти подготовку и отправиться на станцию, чтобы прооперировать космонавта.</span></p>',
    shortDescription: 'В главных ролях — Юлия Пересильд, Милош Бикович, Владимир Машков, Олег Новицкий, Антон Шкаплеров, Петр Дубров, Елена Валюшкина.',
    image: {
      name: 'afisha/vizov.webp',
      realName: 'vizov.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Классическое искусство',
        sysName: 'klassicheskoe-iskusstvo',
      },
    ],
    category: {
      name: 'Кино',
      sysName: 'films',
    },
    isFree: false,
    price: 200,
    maxPrice: 1400,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 1,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-08-08T16:00:00',
            endLocal: '2021-08-08T17:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: [
      {
        name: 'afisha/afisha-image-1.png',
        realName: 'DSC_0437.JPG',
        author: 'Наталья Зотова',
        source: 'Архив автора',
      },
    ],
    organization: {
      id: 14562,
      name: 'Городские Зрелищные Кассы',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Городские Зрелищные Кассы',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: false,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 8,
    name: 'Комплекс мастер-классов «Без границ»»',
    ageRestriction: 12,
    description: '<p><span>Первый фильм, снятый в открытом космосе. На Международной космической станции космонавту внезапно стало плохо. Необходимо срочно провести операцию на сердце, но как это сделать в таких непростых условиях? Торакальный хирург Евгения готова пройти подготовку и отправиться на станцию, чтобы прооперировать космонавта.</span></p>',
    shortDescription: 'вокальное искусство, хореографическое творчество, театральное искусство',
    image: {
      name: 'afisha/master-class2.webp',
      realName: 'master-class2.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Классическое искусство',
        sysName: 'klassicheskoe-iskusstvo',
      },
    ],
    category: {
      name: 'Обучение',
      sysName: 'learning',
    },
    isFree: false,
    price: 100,
    maxPrice: 600,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 2,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-08-08T16:00:00',
            endLocal: '2021-08-08T17:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Городские Зрелищные Кассы',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Городские Зрелищные Кассы',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 9,
    name: 'Каникулярная программа «Смарт-каникулы «НеШкола»',
    ageRestriction: 12,
    description: '<p><span>Первый фильм, снятый в открытом космосе. На Международной космической станции космонавту внезапно стало плохо. Необходимо срочно провести операцию на сердце, но как это сделать в таких непростых условиях? Торакальный хирург Евгения готова пройти подготовку и отправиться на станцию, чтобы прооперировать космонавта.</span></p>',
    shortDescription: 'Для школьников окружной столицы в Национальной библиотеке ЯНАО пройдет каникулярная программа,  где ребят будут ждать самые интересные НеУроки.  Летние каникулы в библиотеке пройдут с «Классным чтением»',
    image: {
      name: 'afisha/smart-kanikul.webp',
      realName: 'smart-kanikul.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Классическое искусство',
        sysName: 'klassicheskoe-iskusstvo',
      },
    ],
    category: {
      name: 'Обучение',
      sysName: 'learning',
    },
    isFree: true,
    price: 0,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-08-08T16:00:00',
            endLocal: '2021-08-08T17:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Национальная библиотека Ямало-Ненецкого',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: false,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 10,
    name: 'Персональная выставка Юрия Лисовского «Постижение»',
    ageRestriction: 12,
    description: '<p><span>Первый фильм, снятый в открытом космосе. На Международной космической станции космонавту внезапно стало плохо. Необходимо срочно провести операцию на сердце, но как это сделать в таких непростых условиях? Торакальный хирург Евгения готова пройти подготовку и отправиться на станцию, чтобы прооперировать космонавта.</span></p>',
    shortDescription: 'В культурную программу выставки включены творческая встреча с художником, мастер-классы и экскурсии.',
    image: {
      name: 'afisha/vistavka.webp',
      realName: 'vistavka.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Музеи',
      sysName: 'museums',
    },
    isFree: true,
    price: 0,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-19T12:00:00',
            endLocal: '2021-06-19T20:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Окружной дом ремесел на ул. Свердлова',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 11,
    name: 'Кинопоказ «Хроники Панголинов»',
    ageRestriction: 12,
    description: '<p><span>Первый фильм, снятый в открытом космосе. На Международной космической станции космонавту внезапно стало плохо. Необходимо срочно провести операцию на сердце, но как это сделать в таких непростых условиях? Торакальный хирург Евгения готова пройти подготовку и отправиться на станцию, чтобы прооперировать космонавта.</span></p>',
    shortDescription: 'В один прекрасный день жизнь Верити и Питера переворачивается с ног на голову, когда пол в их комнате внезапно рушится, и они падают в подземный мир панголинов. Там они узнают, что с Питером связано таинственное предсказание. Только он сможет перезапустить время, спасти мир.',
    image: {
      name: 'afisha/hroniki.webp',
      realName: 'hroniki.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Приключения',
        sysName: 'priklucheniya',
      },
    ],
    category: {
      name: 'Кино',
      sysName: 'films',
    },
    isFree: false,
    price: 250,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-15T00:00:00',
            endLocal: '2021-06-28T00:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Окружной центр национальных культур г. Салехарда',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: false,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 12,
    name: 'Экскурсия по усадьбе с чаепитием',
    ageRestriction: 12,
    description: '<p><span>Первый фильм, снятый в открытом космосе. На Международной космической станции космонавту внезапно стало плохо. Необходимо срочно провести операцию на сердце, но как это сделать в таких непростых условиях? Торакальный хирург Евгения готова пройти подготовку и отправиться на станцию, чтобы прооперировать космонавта.</span></p>',
    shortDescription: 'Во время увлекательной экскурсии вы узнаете, какие люди и как здесь жили в былые времена, какими народными ремеслами владели.Завершится экскурсия чаепитием на кухне.',
    image: {
      name: 'afisha/usadba.webp',
      realName: 'usadba.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Для молодежи',
        sysName: 'dlya molodezh',
      },
    ],
    category: {
      name: 'Туризм',
      sysName: 'tourism',
    },
    isFree: false,
    price: 330,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-19T13:00:00',
            endLocal: '2021-06-19T18:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Комплекс городской усадьбы г. Салехард',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 13,
    name: 'Выставка-продажа сувениров Ямала',
    ageRestriction: 0,
    description: '<p><span>Первый фильм, снятый в открытом космосе. На Международной космической станции космонавту внезапно стало плохо. Необходимо срочно провести операцию на сердце, но как это сделать в таких непростых условиях? Торакальный хирург Евгения готова пройти подготовку и отправиться на станцию, чтобы прооперировать космонавта.</span></p>',
    shortDescription: 'Постоянно действующая выставка работ ямальских мастеров и рукодельниц.',
    image: {
      name: 'afisha/yarmarka.webp',
      realName: 'yarmarka.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Музеи',
      sysName: 'museums',
    },
    isFree: true,
    price: 0,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-19T12:00:00',
            endLocal: '2021-06-19T20:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Окружной центр национальных культур г. Салехарда',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: false,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 14,
    name: 'Занятия в Центре эстрадного творчества',
    ageRestriction: 6,
    description: '<p><span>Первый фильм, снятый в открытом космосе. На Международной космической станции космонавту внезапно стало плохо. Необходимо срочно провести операцию на сердце, но как это сделать в таких непростых условиях? Торакальный хирург Евгения готова пройти подготовку и отправиться на станцию, чтобы прооперировать космонавта.</span></p>',
    shortDescription: 'Центр предназначен для детей старше 6 лет. Программа обучения включает в себя уроки по вокалу, хореографии и актерскому мастерству. ',
    image: {
      name: 'afisha/estrada.webp',
      realName: 'estrada.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Спорт и игры',
      sysName: 'sport-and-games',
    },
    isFree: false,
    price: 300,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-19T12:00:00',
            endLocal: '2021-06-19T20:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Окружной центр национальных культур г. Салехарда',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 15,
    name: 'Занятия в театре танца «Nota Bene»',
    ageRestriction: 6,
    description: '<p><span>Первый фильм, снятый в открытом космосе. На Международной космической станции космонавту внезапно стало плохо. Необходимо срочно провести операцию на сердце, но как это сделать в таких непростых условиях? Торакальный хирург Евгения готова пройти подготовку и отправиться на станцию, чтобы прооперировать космонавта.</span></p>',
    shortDescription: ' Программа обучения включает в себя воспитание гибкого и тренированного психофизического аппарата (музыкального тела) средствами хореографического искусства музыкального тела',
    image: {
      name: 'afisha/nota-bene.webp',
      realName: 'nota-bene.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Спорт и игры',
      sysName: 'sport-and-games',
    },
    isFree: false,
    price: 250,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-19T12:00:00',
            endLocal: '2021-06-19T20:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Окружной центр национальных культур г. Салехарда',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: false,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 16,
    name: 'Запись в ансамбль танца народов Северного Кавказа «Сияние гор»',
    ageRestriction: 12,
    description: '<p><span>Первый фильм, снятый в открытом космосе. На Международной космической станции космонавту внезапно стало плохо. Необходимо срочно провести операцию на сердце, но как это сделать в таких непростых условиях? Торакальный хирург Евгения готова пройти подготовку и отправиться на станцию, чтобы прооперировать космонавта.</span></p>',
    shortDescription: 'Занятия в коллективе многофункциональны: это и средство общего развития ребенка, его индивидуально-психологических способностей, двигательных умений и навыков, и форма взаимодействия и сотрудничества',
    image: {
      name: 'afisha/siyanie-gor.webp',
      realName: 'siyanie-gor.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Для детей',
      sysName: 'dlya-detey',
    },
    isFree: false,
    price: 250,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-19T12:00:00',
            endLocal: '2021-06-19T20:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Окружной центр национальных культур г. Салехарда',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: false,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 17,
    name: 'Кинопоиск «Мармадюк»',
    ageRestriction: 6,
    description: '<p><span>Первый фильм, снятый в открытом космосе. На Международной космической станции космонавту внезапно стало плохо. Необходимо срочно провести операцию на сердце, но как это сделать в таких непростых условиях? Торакальный хирург Евгения готова пройти подготовку и отправиться на станцию, чтобы прооперировать космонавта.</span></p>',
    shortDescription: 'Очаровательный, но неуправляемый дог Мармадюк больше всего на свете любит веселиться со своей семьей, особенно с девятилетним Билли, который снимает крутые видосики. ',
    image: {
      name: 'afisha/marmaduk.webp',
      realName: 'marmaduk.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Кино',
      sysName: 'films',
    },
    isFree: false,
    price: 200,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-19T10:40:00',
            endLocal: '2021-06-19T12:15:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Окружной центр национальных культур г. Салехарда',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 18,
    name: 'Летняя площадка «Место чтения»',
    ageRestriction: 0,
    description: '<p><span>Первый фильм, снятый в открытом космосе. На Международной космической станции космонавту внезапно стало плохо. Необходимо срочно провести операцию на сердце, но как это сделать в таких непростых условиях? Торакальный хирург Евгения готова пройти подготовку и отправиться на станцию, чтобы прооперировать космонавта.</span></p>',
    shortDescription: 'Место чтения — это прекрасная возможность весело и с пользой провести время во время прогулки. Каждый раз готовим интересную программу, подбираем интересные журналы и книги для увлекательного чтения . ',
    image: {
      name: 'afisha/chtenie.webp',
      realName: 'chtenie.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Образование',
      sysName: 'learning',
    },
    isFree: true,
    price: 0,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-20T17:00:00',
            endLocal: '2021-06-20T20:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Национальная библиотека Ямало-Ненецкого автономного округа',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: false,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 19,
    name: 'Мастер-класс «Готовим в русской печи»',
    ageRestriction: 12,
    description: '<p><span>Первый фильм, снятый в открытом космосе. На Международной космической станции космонавту внезапно стало плохо. Необходимо срочно провести операцию на сердце, но как это сделать в таких непростых условиях? Торакальный хирург Евгения готова пройти подготовку и отправиться на станцию, чтобы прооперировать космонавта.</span></p>',
    shortDescription: 'Ищите, куда сходить с детьми или друзьями? Хотите необычно провести время? А может давно мечтали научиться готовить? Приходите в усадьбу на кулинарный мастер-класс «Готовим в русской печи»!',
    image: {
      name: 'afisha/gotovim.webp',
      realName: 'gotovim.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Обучени',
      sysName: 'learning',
    },
    isFree: false,
    price: 600,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-19T12:00:00',
            endLocal: '2021-06-19T20:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Комплекс городской усадьбы г. Салехард',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 20,
    name: 'Интерактивная выставка изобретений Леонардо да Винчи',
    ageRestriction: 6,
    description: '<p><span>Первый фильм, снятый в открытом космосе. На Международной космической станции космонавту внезапно стало плохо. Необходимо срочно провести операцию на сердце, но как это сделать в таких непростых условиях? Торакальный хирург Евгения готова пройти подготовку и отправиться на станцию, чтобы прооперировать космонавта.</span></p>',
    shortDescription: 'Это частный проект Геннадия Куронова (Москва). В экспозиции будут представлены механизмы, воссозданные по чертежам Леонардо да Винчи современными российскими мастерами. ',
    image: {
      name: 'afisha/leonardo.webp',
      realName: 'leonardo.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Образование',
      sysName: 'learning',
    },
    isFree: false,
    price: 350,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-20T12:00:00',
            endLocal: '2021-06-20T21:30:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Ямало-Ненецкий окружной музейно-выставочный комплекс имени И. С. Шемановского',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: false,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 21,
    name: 'Занятия в фольклорном ансамбле «Матрёша»',
    ageRestriction: null,
    description: '<p><span>Первый фильм, снятый в открытом космосе. На Международной космической станции космонавту внезапно стало плохо. Необходимо срочно провести операцию на сердце, но как это сделать в таких непростых условиях? Торакальный хирург Евгения готова пройти подготовку и отправиться на станцию, чтобы прооперировать космонавта.</span></p>',
    shortDescription: 'Программа обучения рассчитана возрастную категорию от 5 до 14 лет. В курс обучения входит сольное и ансамблевое народное пение, знакомство с народными инструментами и первоначальные навыки игры, бытовой народный танец, знакомство с традициями и обрядами, прикладное творчество и сценическое мастерство. ',
    image: {
      name: 'afisha/matresha.webp',
      realName: 'matresha.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Для детей',
      sysName: 'dlya-detey',
    },
    isFree: false,
    price: 0,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-20T12:00:00',
            endLocal: '2021-06-20T14:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Окружной центр национальных культур г. Салехарда',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 22,
    name: 'Выставка «Игры с вечностью»',
    ageRestriction: 12,
    description: '<p><span>Первый фильм, снятый в открытом космосе. На Международной космической станции космонавту внезапно стало плохо. Необходимо срочно провести операцию на сердце, но как это сделать в таких непростых условиях? Торакальный хирург Евгения готова пройти подготовку и отправиться на станцию, чтобы прооперировать космонавта.</span></p>',
    shortDescription: 'Выставочный проект художника Юрия Шахояна (Новосибирск) — это осмысление вечности через трансформацию привычных вещей. ',
    image: {
      name: 'afisha/igra-vechno.webp',
      realName: 'igra-vechno.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Экскурсия',
      sysName: 'tourism',
    },
    isFree: false,
    price: 100,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-20T12:00:00',
            endLocal: '2021-06-20T21200:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Ямало-Ненецкий окружной музейно-выставочный комплекс имени И. С. Шемановского',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: false,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 23,
    name: 'Выставка Хабэчи Яунгада «365 дней под открытым небом»',
    ageRestriction: 6,
    description: '<p><span> Выставка познакомит с творчеством Хабэчи Яунгада — члена Союза журналистов России, который прошёл путь от переводчика до главного редактора окружной газеты на ненецком языке «Няръяна Нгэрм». В 2007 году ему присвоено звание Почетный гражданин Ямало-Ненецкого автономного округа.</span></p>',
    shortDescription: ' Выставка познакомит с творчеством Хабэчи Яунгада — члена Союза журналистов России, который прошёл путь от переводчика до главного редактора окружной газеты на ненецком языке «Няръяна Нгэрм»',
    image: {
      name: 'afisha/365.webp',
      realName: '365.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Для детей',
      sysName: 'dlya-detey',
    },
    isFree: false,
    price: 0,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-20T10:40:00',
            endLocal: '2021-06-20T20:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Природно-этнографический комплекс п. Горнокнязевск',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Окружной центр национальных культур г. Салехарда',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: false,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 24,
    name: 'Выставка «Кристальный порядок»',
    ageRestriction: 6,
    description: '<p><span>Выставочный проект посвящен миру кристаллов и науке кристаллографии — геологической дисциплине на стыке химии, математики, физики и биологии. </span></p>',
    shortDescription: 'Выставочный проект посвящен миру кристаллов и науке кристаллографии — геологической дисциплине на стыке химии, математики, физики и биологии.',
    image: {
      name: 'afisha/poryadok.webp',
      realName: 'poryadok.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Для детей',
      sysName: 'dlya-detey',
    },
    isFree: false,
    price: 0,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-20T12:00:00',
            endLocal: '2021-06-20T21:30:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Ямало-Ненецкий окружной музейно-выставочный комплекс имени И. С. Шемановского',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 25,
    name: 'Экскурсия с обрядами',
    ageRestriction: 6,
    description: '<p><span>Природно-этнографический комплекс в поселке Горнокнязевск — это уникальный уголок Ямала. Гостей здесь ждут национальные обряды, традиционные блюда, колоритная одежда коренных народов Севера и путешествие в мир первозданной и самобытной красоты, где с древних времен человек живет в гармонии с природой.</span></p>',
    shortDescription: 'Выставочный проект посвящен миру кристаллов и науке кристаллографии — геологической дисциплине на стыке химии, математики, физики и биологии.',
    image: {
      name: 'afisha/obryad.webp',
      realName: 'obryad.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Для детей',
      sysName: 'dlya-detey',
    },
    isFree: false,
    price: 500,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    gallery: null,
    organization: {
      id: 14562,
      name: 'Природно-этнографический комплекс п. Горнокнязевск',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: false,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 26,
    name: 'Выставка «Оттаявший мир»',
    ageRestriction: 6,
    description: '<p><span>«Оттаявший мир» — новое название выставки «Время мамонта», где «прописан» всемирно известный экспонат мамонтенок Люба, Монгоченский мамонт, шерстистый носорог и другие представители ископаемой фауны и флоры.</span></p>',
    shortDescription: '«Оттаявший мир» — новое название выставки «Время мамонта», где «прописан» всемирно известный экспонат мамонтенок Люба, Монгоченский мамонт, шерстистый носорог и другие представители ископаемой фауны и флоры.',
    image: {
      name: 'afisha/mamont.webp',
      realName: 'mamont.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: '',
      sysName: 'dlya-detey',
    },
    isFree: false,
    price: 75,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-20T12:00:00',
            endLocal: '2021-06-20T21:30:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Ямало-Ненецкий окружной музейно-выставочный комплекс имени И. С. Шемановского',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 27,
    name: 'Клуб «Любители народного творчества»',
    ageRestriction: 6,
    description: '<p><span>Клубное формирование «Любители народного творчества» предназначено для детей от 5-ти лет. В программе обучения: знакомство с историей возникновения сувенира, изучение процесса изготовления сувениров, участие в творческих выставках так же занятия по различным видам рукоделия, воссоздание славянских традиций, обрядов и забытых праздников, изучение старинных кулинарных рецептов.</span></p>',
    shortDescription: '«Клубное формирование «Любители народного творчества» предназначено для детей от 5-ти лет. В программе обучения: знакомство с историей возникновения сувенира, изучение процесса изготовления сувениров, участие в творческих выставках так же занятия по различным видам рукоделия, воссоздание славянских традиций, обрядов и забытых праздников, изучение старинных кулинарных рецептов.',
    image: {
      name: 'afisha/tvorchestvo.webp',
      realName: 'tvorchestvo.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Для детей',
      sysName: 'dlya-detey',
    },
    isFree: false,
    price: 0,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-20T16:00:00',
            endLocal: '2021-06-20T20:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Комплекс городской усадьбы г. Салехард',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: false,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 28,
    name: 'Занятия в ненецкой фольклорной группе «Вы сей»',
    ageRestriction: 6,
    description: '<p><span>Группа предназначена для детей старше 6 лет. Программа обучения нацелена на сохранение и пропаганду национальных традиций ненецкого народа; песенное творчество, устный фольклор, авторские и личные песни руководителя группы Татьяны Нюдихасововны Лар также являются неотъемлемой частью занятий.</span></p>',
    shortDescription: '«Программа обучения нацелена на сохранение и пропаганду национальных традиций ненецкого народа; песенное творчество, устный фольклор, авторские и личные песни руководителя группы Татьяны Нюдихасововны Лар также являются неотъемлемой частью занятий.',
    image: {
      name: 'afisha/sey.webp',
      realName: 'sey.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Для детей',
      sysName: 'dlya-detey',
    },
    isFree: false,
    price: 0,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-20T21:00:00',
            endLocal: '2021-06-20T23:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Окружной центр национальных культур г. Салехарда',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 29,
    name: 'Экспресс-курс «Диалоги о микшировании живого звука»',
    ageRestriction: 6,
    description: '<p><span>Вас ждет экспресс-курс активного погружения в специализацию «звукооператор». Слушатели получат сведения, необходимые для работы звукооператора культурно-массовых и концертных мероприятий.Занятия проводит заведующий отделом мультимедиа Центра национальных культур Александр Анатольевич Григорьев.</span></p>',
    shortDescription: '«Вас ждет экспресс-курс активного погружения в специализацию «звукооператор». Слушатели получат сведения, необходимые для работы звукооператора культурно-массовых и концертных мероприятий.',
    image: {
      name: 'afisha/zvuk.webp',
      realName: 'zvuk.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Для детей',
      sysName: 'dlya-detey',
    },
    isFree: false,
    price: 500,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-19T12:00:00',
            endLocal: '2021-06-19T14:00:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Окружной центр национальных культур г. Салехарда',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: false,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 30,
    name: 'Экскурсия в очках дополненной реальности по выставке «Человек Севера»',
    ageRestriction: 6,
    description: '<p><span>Экскурсия по выставке «Человек Севера» с использованием очков Microsoft HoloLens в интерактивном формате рассказывает посетителям об истории заселения территории Нижнего Приобья и полуострова Ямал. Огромное количество артефактов из археологической коллекции музея оживают благодаря игровому контенту, а участники встречи имеют возможность взаимодействовать с разными персонажами той эпохи: купцом, рыбаком, охотником, погружаясь в быт человека Севера.</span></p>',
    shortDescription: 'Экскурсия по выставке «Человек Севера» с использованием очков Microsoft HoloLens в интерактивном формате рассказывает посетителям об истории заселения территории Нижнего Приобья и полуострова Ямал.',
    image: {
      name: 'afisha/virtual.webp',
      realName: 'virtual.webp',
      author: 'БОРИС ХЛЕБНИКОВ',
      source: '«СНЕГИРЬ»',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Для детей',
      sysName: 'dlya-detey',
    },
    isFree: false,
    price: 500,
    maxPrice: 0,
    saleLink: 'http://www.kubanfilarmoniya.ru/afisha/date/2021/08/08/2194/#modal',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-21T16:00:00',
            endLocal: '2021-06-21T16:45:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Новый Уренгой',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Сибирская',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '26',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Ямало-Ненецкий окружной музейно-выставочный комплекс имени И. С. Шемановского',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1628427600000,
    end: 1628431200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 31,
    name: 'Интеллектуальная игра «ПушКвиз»',
    ageRestriction: 6,
    description: '<p><span>Квиз — это возможность для старшеклассников интересно и с пользой провести досуг, научиться работать в команде и пополнить багаж знаний. Несколько команд будут состязаться друг с другом, отвечая на вопросы в течение заданного времени.</span></p>',
    shortDescription: 'Квиз — это возможность для старшеклассников интересно и с пользой провести досуг, научиться работать в команде и пополнить багаж знаний.',
    image: {
      name: 'afisha/quiz.webp',
      realName: 'quiz.webp',
      author: 'HTTPS://PHONOTEKA.ORG/23163-MOLODEZH-FON.HTML',
      source: 'HTTPS://PHONOTEKA.ORG/23163-MOLODEZH-FON.HTML',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Для детей',
      sysName: 'dlya-detey',
    },
    isFree: false,
    price: 400,
    maxPrice: 0,
    saleLink: 'https://www.culture.ru/events/1552637/intellektualnaya-igra-pushkviz?location=yamalo-neneckiy-avtonomnyy-okrug',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-21T16:00:00',
            endLocal: '2021-06-21T16:45:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Салехард',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Республики',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '74',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'влд',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Ямало-Ненецкий окружной музейно-выставочный комплекс имени И. С. Шемановского',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1640217600000,
    end: 1687996800000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 32,
    name: 'Квиз «Чтобы мне поделать, только бы не поиграть?»',
    ageRestriction: 6,
    description: '<p><span>Квиз — это возможность для старшеклассников интересно и с пользой провести досуг, научиться работать в команде и пополнить багаж знаний. Несколько команд будут состязаться друг с другом, отвечая на вопросы в течение заданного времени.</span></p>',
    shortDescription: 'Квиз разработан специально для целеустремленных молодых людей, готовых проявить свои знания, смекалку и решить любую нестандартную задачку.',
    image: {
      name: 'afisha/quiz2.webp',
      realName: 'quiz2.webp',
      author: 'НАЦИОНАЛЬНАЯ БИБЛИОТЕКА ЯМАЛА',
      source: 'АРХИВ НАЦИОНАЛЬНАЯ БИБЛИОТЕКА ЯМАЛА',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Для детей',
      sysName: 'dlya-detey',
    },
    isFree: false,
    price: 400,
    maxPrice: 0,
    saleLink: 'https://www.culture.ru/events/2695629/kviz-chtoby-mne-podelat-tolko-by-ne-poigrat?location=yamalo-neneckiy-avtonomnyy-okrug',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-21T16:00:00',
            endLocal: '2021-06-21T16:45:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Салехард',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Чубынина',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '36',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'влд',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Ямало-Ненецкий окружной музейно-выставочный комплекс имени И. С. Шемановского',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1678320000000,
    end: 1703894400000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 33,
    name: 'Занятия в театре-студии «Каморка»',
    ageRestriction: 6,
    description: '<p><span>Студия предназначена для детей старше 12 лет. Программа обучения включает в себя уроки по актерскому мастерству, способствующие раскрытию творческого потенциала, снятию «зажимов» и страхов выступать на широкую публику. Также в студии регулярно проходят занятия-тренинги по сценическому движению и бою, сценической речи, по актерской психологии, по хореографии, пластике и акробатике.</span></p>',
    shortDescription: 'Программа обучения включает в себя уроки по актерскому мастерству, способствующие раскрытию творческого потенциала, снятию «зажимов» и страхов выступать на широкую публику.',
    image: {
      name: 'afisha/kamorka.webp',
      realName: 'kamorka.webp',
      author: 'ДОЛГУШИНА НАЗГУЛЬ РАФИСОВНА',
      source: 'ЛИЧНЫЙ АРХИВ',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Для детей',
      sysName: 'dlya-detey',
    },
    isFree: false,
    price: 200,
    maxPrice: 0,
    saleLink: 'https://www.culture.ru/events/1326426/zanyatiya-v-teatre-studii-kamorka?location=yamalo-neneckiy-avtonomnyy-okrug',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-21T16:00:00',
            endLocal: '2021-06-21T16:45:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Салехард',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Республики',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '74',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'влд',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Ямало-Ненецкий окружной музейно-выставочный комплекс имени И. С. Шемановского',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1631318400000,
    end: 1735516800000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 34,
    name: 'Встреча «Among Us в реальном музее»',
    ageRestriction: 6,
    description: '<p><span>Детективно-ролевая игра по мотивам компьютерной игры «Among Us», где участники должны рассекретить предателя в рядах своей команды на борту космического корабля, пока он всех тайно не уничтожил.</span></p>',
    shortDescription: 'Детективно-ролевая игра по мотивам компьютерной игры «Among Us».',
    image: {
      name: 'afisha/among-us_meeting.webp',
      realName: 'among-us_meeting.webp',
      author: 'ВЕРА ПАЦЕВА',
      source: 'ФОТОАРХИВ ГБУ "МВК"',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Для детей',
      sysName: 'dlya-detey',
    },
    isFree: false,
    price: 400,
    maxPrice: 0,
    saleLink: 'https://www.culture.ru/events/1285371/vstrecha-among-us-v-realnom-muzee?location=yamalo-neneckiy-avtonomnyy-okrug',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-21T16:00:00',
            endLocal: '2021-06-21T16:45:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Салехард',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Чубынина',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '38',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Ямало-Ненецкий окружной музейно-выставочный комплекс имени И. С. Шемановского',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1634860800000,
    end: 1688083200000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 35,
    name: 'Программа «Арт-общага»',
    ageRestriction: 6,
    description: '<p><span>Слово «общага» вызывает у человека разные эмоции. Для кого-то это воспоминания о веселых студенческих буднях, для кого-то — о дружбе и любви, а есть и те, кто вспоминает бессонные ночи, погруженные в учебники, конспекты, и сессию.</span></p>',
    shortDescription: '«Арт-общага» — это пространство для творческой и талантливой молодежи, здесь каждый найдет себе нужную комнату.',
    image: {
      name: 'afisha/art_dormitory.webp',
      realName: 'art_dormitory.webp',
      author: 'ГАУК ЯНАО "ОКРУЖНОЙ ДОМ РЕМЁСЕЛ"',
      source: 'HTTPS://ODR.YANAO.RU/',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Для детей',
      sysName: 'dlya-detey',
    },
    isFree: false,
    price: 1000,
    maxPrice: 0,
    saleLink: 'https://www.culture.ru/events/3025842/programma-art-obshaga?location=yamalo-neneckiy-avtonomnyy-okrug',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-21T16:00:00',
            endLocal: '2021-06-21T16:45:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Салехард',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Чубынина',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '24',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Ямало-Ненецкий окружной музейно-выставочный комплекс имени И. С. Шемановского',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1682726400000,
    end: 1703980800000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
  {
    id: 36,
    name: 'Квест «Тайна старого дома»',
    ageRestriction: 6,
    description: '<p><span>Вернитесь в прошлое. События происходят в конце XIX века на территории старого города Обдорска. Старинный дом, как поговаривают местные, полон тайн и загадок. Истошные крики о помощи сменяются мертвой тишиной, дикий вой переходит в шепот, скрипы, скрежеты будоражат не только воображение, но и кровь. Люди, проживающие в доме, редко появляются на улице.</span></p>',
    shortDescription: 'Участникам предстоит выполнить задания и узнать, какую тайну хранит в себе старинный дом. На каждом этапе их ждут интересные испытания.',
    image: {
      name: 'afisha/quest.webp',
      realName: 'quest.webp',
      author: 'ГАУК ЯНАО «ОКРУЖНОЙ ДОМ РЕМЕСЕЛ»',
      source: 'HTTPS://ODR.YANAO.RU/',
    },
    tags: [
      {
        id: 158,
        name: 'Современное искусство',
        sysName: 'sovremennoe-iskusstvo',
      },
    ],
    category: {
      name: 'Для детей',
      sysName: 'dlya-detey',
    },
    isFree: false,
    price: 600,
    maxPrice: 0,
    saleLink: 'https://www.culture.ru/events/2268633/kvest-taina-starogo-doma?location=yamalo-neneckiy-avtonomnyy-okrug',
    extraFields: {},
    places: [
      {
        id: 12469,
        locale: {
          id: 1449,
          sysName: 'krasnodar',
          name: 'Краснодар',
          timezone: 'Europe/Moscow',
          isYandexExport: true,
        },
        seances: [
          {
            start: 1628427600000,
            end: 1628431200000,
            startLocal: '2021-06-21T16:00:00',
            endLocal: '2021-06-21T16:45:00',
          },
        ],
        address: {
          region: {
            name: 'Ямало-Ненецкий АО',
            fiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
            type: 'АО',
            isPostfix: true,
          },
          city: {
            name: 'Салехард',
            fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
            type: 'г',
          },
          street: {
            name: 'Ламбиных',
            fiasId: '78b0d0f2-d796-4f6c-a099-9379b267afc7',
            type: 'ул',
          },
          house: {
            name: '1',
            fiasId: '6e32a74c-7aaa-43a3-8b4a-b45cffce18d4',
            type: 'д',
          },
          comment: 'автобус № 2е, 3, 26, 67, 95, 101, 106а, 107а, 173а; троллейбус № 1, 2, 4, 6, 8, 9, 10, 15, 31; трамвай № 2, 4, 5, 8, 11, 15; маршр. такси № 5, 7а, 7б, 8, 9, 12, 15, 20, 21, 27, 28, 30, 37, 39, 45, 65, 196, 201',
        },
        category: {
          name: 'Концертные площадки',
          sysName: 'koncertnye-ploshchadki',
        },
        image: {
          name: '149143ce80736022b12162e1623c89da.jpg',
          realName: 'зал.jpg',
          author: 'Архив Краснодарской филармонии',
          source: 'https://vk.com/photo-14476753_333561177',
        },
        localeIds: [
          1449,
          175,
          1,
        ],
        name: 'Краснодарская филармония имени Г. Ф. Пономаренко',
        mapPosition: {
          type: 'Point',
          coordinates: [
            45.027075,
            38.971019,
          ],
        },
      },
    ],
    gallery: null,
    organization: {
      id: 14562,
      name: 'Ямало-Ненецкий окружной музейно-выставочный комплекс имени И. С. Шемановского',
      locale: {
        id: 1,
        name: 'РФ',
        sysName: 'rf',
        timezone: 'Europe/Moscow',
        isYandexExport: false,
      },
      address: {
        region: {
          name: 'Чувашская республика',
          type: 'Чувашия',
          fiasId: '878fc621-3708-46c7-a97f-5a13a4176b3e',
          isPostfix: true,
        },
        city: {
          name: 'Чебоксары',
          type: 'г',
          fiasId: 'dd8caeab-c685-4f2a-bf5f-550aca1bbc48',
        },
        street: {
          name: 'Университетская',
          type: 'ул',
          fiasId: '9f3f6696-f195-4835-90f4-6312a5508380',
          isPostfix: true,
        },
        house: {
          name: '38',
          type: '22d49dc5-8f51-4141-a405-3796cf26e0c7',
          fiasId: 'д',
        },
      },
      type: 'commercial',
      inn: '7453098727',
      subordination: {
        id: 2591,
        name: 'Челябинск',
        timezone: 'Asia/Yekaterinburg',
        sysName: 'chelyabinsk',
        isYandexExport: true,
      },
      members: [
        12354,
        69511,
        75734,
        32632,
        29296,
      ],
      localeIds: [
        2591,
        213,
        1,
      ],
      subordinationIds: [
        2591,
        213,
        1,
      ],
      roles: {},
      socialGroups: [],
      createDate: 1467983023303,
      updateDate: 1652844839301,
      postingUserId: 13798,
      support: 0,
      rating: {
        entities: 14,
        rejects: 9,
        ratio: 0.64,
        group: 'regular',
      },
      nameShort: 'Национальная библиотека Ямало-Ненецкого АО',
      category: {
        name: 'Встречи',
        sysName: 'vstrechi',
        type: 'events',
      },
      canAutoModerate: true,
      autoModerateCandidate: false,
      mastersClasses: false,
      isPushkinsCard: true,
      pushkaRulesAccepted: {
        status: true,
        user: {
          id: 75734,
          fullName: 'Мария Шувалова',
        },
        date: 1633951489364,
      },
      isTicketsSystem: true,
      ticketsSold: 966,
      isPrivate: true,
    },
    status: 'accepted',
    updateDate: 1626963062849,
    author: {
      id: 174,
      fullName: 'Мария Треногина',
    },
    seances: [
      {
        placeIndex: 0,
        start: 1628427600000,
        end: 1628431200000,
      },
    ],
    start: 1666224000000,
    end: 1703980800000,
    createDate: 1626681193155,
    duplicates: {
      new: [
        179517,
      ],
    },
    altName: 'Концерт «Опера, опера, опера!»',
    needCheck: false,
    chat: {
      id: 835168,
    },
    inAccepted: true,
    acceptDate: 1628843361853,
    isPublished: true,
    statusPushka: 'accepted',
    externalInfo: [
      {
        views: 6,
        likes: 0,
        updateDate: 1634278920000,
        url: 'https://mkrf.ru/press/culture_life/kontsert_opera_opera_opera__20210722201102_60f9a6a66fc8a/',
        statuses: [
          'published',
        ],
        createDate: 1634278921857,
        serviceName: 'Обновленный mkrf',
        serviceUrl: 'http://new.mkrf.ru/',
      },
      {
        views: 22,
        url: 'https://gorodzovet.ru/krasnodar/kontsert-opera-opera-opera-event8609934',
        statuses: [
          'published',
        ],
        updateDate: 1628426666000,
        createDate: 1635471536131,
        serviceName: 'Город зовёт',
        serviceUrl: 'https://gorodzovet.ru',
      },
    ],
  },
];
