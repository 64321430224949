/* eslint-disable max-len */

export const votes = [
  {
    id: 0, title: 'провели голосований', votesAll: 413, votes: 29, isLocal: true, hasLabel: true, location: 'Новый Уренгой',
  },
  {
    id: 1, title: 'провели голосований', votesAll: 54122, votes: 10234, location: 'Ноябрьск',
  },
  {
    id: 2, title: 'провели голосований', votesAll: 442212, votes: 64, isLocal: true, location: 'Новый Уренгой',
  },
  {
    id: 3, title: 'провели голосований', votesAll: 64565, votes: 4412, hasLabel: true, location: 'Ноябрьск',
  },
  {
    id: 4, title: 'провели голосований', votesAll: 333214, votes: 112, isLocal: true, hasLabel: true, location: 'Губкинский',
  },
  {
    id: 5, title: 'провели голосований', votesAll: 6786, votes: 444, location: 'Губкинский',
  },
  {
    id: 6, title: 'провели голосований', votesAll: 53411, votes: 29032, isLocal: true, location: 'Губкинский',
  },
  {
    id: 7, title: 'провели голосований', votesAll: 413, votes: 29, hasLabel: true, location: 'Лабытнанги',
  },
  {
    id: 8, title: 'провели голосований', votesAll: 9558, votes: 155, location: 'Лабытнанги',
  },
  {
    id: 9, title: 'провели голосований', votesAll: 523411, votes: 29032, isLocal: true, location: 'Лабытнанги',
  },
  {
    id: 10, title: 'провели голосований', votesAll: 333413, votes: 29, hasLabel: true, location: 'Лабытнанги',
  },
  {
    id: 11, title: 'провели голосований', votesAll: 219558, votes: 155, location: 'Лабытнанги',
  },
];

export const cards = [
  {
    id: 0, firstImg: '/images/result-card/result-card.jpg', location: 'Новый Уренгой', title: 'Освещение улиц', year: '2019', label: true,
  },
  {
    id: 1, firstImg: '/images/result-card/result-split-1.jpg', secondImg: '/images/result-card/result-split-2.jpg', isSplit: true, location: 'Новый Уренгой', title: 'Установка лавочек, беседок во дворах', year: '2019',
  },
  {
    id: 2, firstImg: '/images/result-card/result-card.jpg', location: 'Новый Уренгой', title: 'Освещение улиц', year: '2019', label: true,
  },
  {
    id: 3, firstImg: '/images/result-card/result-split-1.jpg', secondImg: '/images/result-card/result-split-2.jpg', isSplit: true, location: 'Новый Уренгой', title: 'Установка лавочек, беседок во дворах', year: '2019',
  },
  {
    id: 5, firstImg: '/images/result-card/result-card.jpg', location: 'Новый Уренгой', title: 'Освещение улиц', year: '2019', label: true,
  },
  {
    id: 6, firstImg: '/images/result-card/result-split-1.jpg', secondImg: '/images/result-card/result-split-2.jpg', isSplit: true, location: 'Новый Уренгой', title: 'Установка лавочек, беседок во дворах', year: '2019',
  },
  {
    id: 7, firstImg: '/images/result-card/result-card.jpg', location: 'Новый Уренгой', title: 'Освещение улиц', year: '2019', label: true,
  },
  {
    id: 8, firstImg: '/images/result-card/result-split-1.jpg', secondImg: '/images/result-card/result-split-2.jpg', isSplit: true, location: 'Новый Уренгой', title: 'Установка лавочек, беседок во дворах', year: '2019',
  },
  {
    id: 9, firstImg: '/images/result-card/result-split-1.jpg', secondImg: '/images/result-card/result-split-2.jpg', isSplit: true, location: 'Губкинский', title: 'Установка лавочек, беседок во дворах', year: '2019',
  },
  {
    id: 10, firstImg: '/images/result-card/result-split-1.jpg', secondImg: '/images/result-card/result-split-2.jpg', isSplit: true, location: 'Лабытнанги', title: 'Установка лавочек', year: '2023',
  },
];

export const images = [
  {
    id: 0, firstImg: '/images/actual-event-card.jpg', secondImg: '/images/result-card/result-card.jpg', title: 'title',
  },
  {
    id: 1, firstImg: '/images/result-card/result-split-1.jpg', secondImg: '/images/actual-event-card.jpg', title: 'title',
  },
  {
    id: 2, firstImg: '/images/result-card/result-card.jpg', secondImg: '/images/result-card/result-split-2.jpg', title: 'title',
  },
  {
    id: 3, firstImg: '/images/result-card/result-split-2.jpg', secondImg: '/images/result-card/result-split-1.jpg', title: 'title',
  },
];

export const dataGallery = [
  {
    name: 'discussions/discussions-gallery-image-1.jpg',
    realName: '1.jpg',
    author: 'Антон Мясин',
    source: 'https://vk.com/dkchgu',
  },
  {
    name: 'discussions/discussions-gallery-image-2.png',
    realName: '2.png',
    author: 'Стас Дергунов',
    source: 'https://vk.com/dkchgu',
  },
  {
    name: 'discussions/discussions-gallery-image-3.png',
    realName: '3.png',
    author: 'Антон Мясин',
    source: 'https://vk.com/dkchgu',
  },
  {
    name: 'discussions/discussions-gallery-image-4.png',
    realName: '4.png',
    author: 'Стас Дергунов',
    source: 'https://vk.com/dkchgu',
  },
];

const data = [
  {
    id: 1,
    type: 'result',
    title: 'установка лавочек во дворах жилых домов',
    description: 'В начале лета было принято решение заменить лавочки у многоквартирных домов и общественных пространств. Управляющие компании провели ремонт и, где необходимо, замену. Эта работа будет продолжаться. Важно, чтобы территория рядом с домом была благоустроенной и комфортной.',
    coords: '66.0907105, 76.6723576,14',
    locationFull: 'Новый Уренгой, ул. Пушкина, дом Колотушкина, Парк «Культурных моржей»',
    date: '10.05.2023',
    end: 1651338000000,
    start: 1838427600000,
    images: [{ image: '/images/actual-event-card.jpg', title: 'title' }, { image: '/images/result-card/result-card.jpg', title: 'title' }],
    gallery: dataGallery,
    location: 'Новый Уренгой',
    label: true,
    resultText: 'В начале лета было принято решение заменить лавочки у многоквартирных домов и общественных пространств. Управляющие компании провели ремонт и, где необходимо, замену. Эта работа будет продолжаться. Важно, чтобы территория рядом с домом была благоустроенной и комфортной.',
    step: 5,
  },
  {
    id: 2,
    type: 'result',
    title: 'установка лавочек',
    description: 'В начале лета было принято решение заменить лавочки у многоквартирных домов и общественных пространств.',
    coords: '66.0907105, 76.6723576,14.',
    locationFull: 'Новый Уренгой, ул. Пушкина, дом Колотушкина, Парк «Культурных моржей»',
    date: '10.05.2023',
    end: 1651338000000,
    images: [{ image: 'result-card/result-split-1.jpg', title: 'title' }, { image: 'actual-event-card.jpg', title: 'title' }],
    gallery: dataGallery,
    location: 'Лабытнанги',
    resultText: 'В начале лета было принято решение заменить лавочки у многоквартирных домов и общественных пространств. Управляющие компании провели ремонт и, где необходимо, замену. Эта работа будет продолжаться. Важно, чтобы территория рядом с домом была благоустроенной и комфортной.',
  },
  // {
  //   id: 2,
  //   title: 'установка чего-нибудь еще',
  //   description: 'Управляющие компании провели ремонт и, где необходимо, замену. Эта работа будет продолжаться. Важно, чтобы территория рядом с домом была благоустроенной и комфортной.',
  //   coords: '66.0907105, 76.6723576,14.',
  //   locationFull: 'Новый Уренгой, ул. Пушкина, дом Колотушкина, Парк «Культурных моржей»',
  //   location: 'Ноябрьск',
  //   end: 1651338000000,
  //   images: [{ image: '/images/result-card/result-card.jpg', title: 'title' }, { image: '/images/result-card/result-split-2.jpg', title: 'title' }],
  //   gallery: dataGallery,
  //   label: true,
  //   resultText: 'В начале лета было принято решение заменить лавочки у многоквартирных домов и общественных пространств. Управляющие компании провели ремонт и, где необходимо, замену. Эта работа будет продолжаться. Важно, чтобы территория рядом с домом была благоустроенной и комфортной.',
  // },
];

export default data;
