/* eslint-disable max-len */
const documents = [
  {
    href: '/upload/Описание отдыха.pdf',
  },
  {
    href: '/upload/Фото с отдыха.jpg',
  },
];

const dataGallery = [
  {
    name: 'afisha/afisha-image-1.png',
    realName: '-scKg2mKFRc.jpeg',
    author: 'Антон Мясин',
    source: 'https://vk.com/dkchgu',
  },
  {
    name: 'afisha/afisha-image-1.png',
    realName: '1.jpeg',
    author: 'Стас Дергунов',
    source: 'https://vk.com/dkchgu',
  },
  {
    name: 'afisha/afisha-image-1.png',
    realName: '-scKg2mKFRc.jpeg',
    author: 'Антон Мясин',
    source: 'https://vk.com/dkchgu',
  },
];

const votess1 = [
  {
    voteID: 1,
    text: {
      title: 'Если у человека день рождения выпадает на праздник, то предлагаю ему продавать по паспорту.',
      content: '',
    },
    voteVolume: 100,
    check: true,
  },
  {
    voteID: 2,
    text: {
      title: 'Если у человека день рождения выпадает на праздник, то предлагаю ему продавать по паспорту.',
      content: '',
    },
    voteVolume: 750,
    check: false,
  },
  {
    voteID: 3,
    text: {
      title: 'Моя идея заключается в том, чтобы была общероссийская база достигших совершеннолетнего возраста.',
      content: 'При розничной продаже алкоголя покупка привязывается к конкретному физическому лицу. В личном кабинете можно посмотреть, сколько алкогольной продукции уже приобретено в этом году, месяце. Невозможно приобрестифы зическому лицу объем спиртного больший допустимого. А норму должны разработать наркологи и другие специалисты здравоохранения. Таким образом, люди, страдающие от алкогольной зависимости, не будут иметь бесконтрольный доступ к спиртным напиткам. Это убережёт будущие поколения от зависимости и заболеваний, которые могут быть вызваны чрезмерным употреблением алкоголя.',
    },
    images: [
      'https://mobimg.b-cdn.net/v3/fetch/4a/4af0bcc2b0c34fd573eca9f1be9ab245.jpeg?w=1470&r=0.5625',
      'https://s1.1zoom.ru/big3/977/Mountains_Scenery_473520.jpg',
    ],
    gallery: dataGallery,
    voteVolume: 500,
    check: false,
  },
  {
    voteID: 4,
    text: {
      title: 'При розничной продаже алкоголя покупка привязывается к конкретному физическому лицу.',
      content: 'Моя идея заключается в том, чтобы была общероссийская база достигших совершеннолетнего возраста. При розничной продаже алкоголя покупка привязывается к конкретному физическому лицу. В личном кабинете можно посмотреть, сколько алкогольной продукции уже приобретено в этом году, месяце. Невозможно приобрестифы зическому лицу объем спиртного больший допустимого. А норму должны разработать наркологи и другие специалисты здравоохранения. Таким образом, люди, страдающие от алкогольной зависимости, не будут иметь бесконтрольный доступ к спиртным напиткам. Это убережёт будущие поколения от зависимости и заболеваний, которые могут быть вызваны чрезмерным употреблением алкоголя.',
    },
    voteVolume: 1000,
    check: true,
  },
];

const votess2 = [
  {
    voteID: 1,
    text: {
      title: 'При розничной продаже алкоголя покупка привязывается к конкретному физическому лицу',
      content: 'Моя идея заключается в том, чтобы была общероссийская база достигших совершеннолетнего возраста. При розничной продаже алкоголя покупка привязывается к конкретному физическому лицу. В личном кабинете можно посмотреть, сколько алкогольной продукции уже приобретено в этом году, месяце. Невозможно приобрестифы зическому лицу объем спиртного больший допустимого. А норму должны разработать наркологи и другие специалисты здравоохранения. Таким образом, люди, страдающие от алкогольной зависимости, не будут иметь бесконтрольный доступ к спиртным напиткам. Это убережёт будущие поколения от зависимости и заболеваний, которые могут быть вызваны чрезмерным употреблением алкоголя.',
    },
    voteVolume: 500,
  },
];

// const votess3 = [
//   {
//     voteID: 1,
//     text: {
//       title: 'При розничной продаже алкоголя покупка привязывается к конкретному физическому лицу',
//       content: 'Моя идея заключается в том, чтобы была общероссийская база достигших совершеннолетнего возраста. При розничной продаже алкоголя покупка привязывается к конкретному физическому лицу. В личном кабинете можно посмотреть, сколько алкогольной продукции уже приобретено в этом году, месяце. Невозможно приобрестифы зическому лицу объем спиртного больший допустимого. А норму должны разработать наркологи и другие специалисты здравоохранения. Таким образом, люди, страдающие от алкогольной зависимости, не будут иметь бесконтрольный доступ к спиртным напиткам. Это убережёт будущие поколения от зависимости и заболеваний, которые могут быть вызваны чрезмерным употреблением алкоголя.',
//     },
//     voteVolume: 100,
//   },
//   {
//     voteID: 2,
//     text: {
//       title: 'При розничной продаже алкоголя покупка привязывается к конкретному физическому лицу',
//       content: 'Моя идея заключается в том, чтобы была общероссийская база достигших совершеннолетнего возраста. При розничной продаже алкоголя покупка привязывается к конкретному физическому лицу. В личном кабинете можно посмотреть, сколько алкогольной продукции уже приобретено в этом году, месяце. Невозможно приобрестифы зическому лицу объем спиртного больший допустимого. А норму должны разработать наркологи и другие специалисты здравоохранения. Таким образом, люди, страдающие от алкогольной зависимости, не будут иметь бесконтрольный доступ к спиртным напиткам. Это убережёт будущие поколения от зависимости и заболеваний, которые могут быть вызваны чрезмерным употреблением алкоголя.',
//     },
//     voteVolume: 231,
//   },
//   {
//     voteID: 3,
//     text: {
//       title: 'При розничной продаже алкоголя покупка привязывается к конкретному физическому лицу',
//       content: 'Моя идея заключается в том, чтобы была общероссийская база достигших совершеннолетнего возраста. При розничной продаже алкоголя покупка привязывается к конкретному физическому лицу. В личном кабинете можно посмотреть, сколько алкогольной продукции уже приобретено в этом году, месяце. Невозможно приобрестифы зическому лицу объем спиртного больший допустимого. А норму должны разработать наркологи и другие специалисты здравоохранения. Таким образом, люди, страдающие от алкогольной зависимости, не будут иметь бесконтрольный доступ к спиртным напиткам. Это убережёт будущие поколения от зависимости и заболеваний, которые могут быть вызваны чрезмерным употреблением алкоголя.',
//     },
//     voteVolume: 132,
//   },
// ];

// const votess4 = [
//   {
//     voteID: 1,
//     text: {
//       title: 'При розничной продаже алкоголя покупка привязывается к конкретному физическому лицу',
//       content: 'Моя идея заключается в том, чтобы была общероссийская база достигших совершеннолетнего возраста. При розничной продаже алкоголя покупка привязывается к конкретному физическому лицу. В личном кабинете можно посмотреть, сколько алкогольной продукции уже приобретено в этом году, месяце. Невозможно приобрестифы зическому лицу объем спиртного больший допустимого. А норму должны разработать наркологи и другие специалисты здравоохранения. Таким образом, люди, страдающие от алкогольной зависимости, не будут иметь бесконтрольный доступ к спиртным напиткам. Это убережёт будущие поколения от зависимости и заболеваний, которые могут быть вызваны чрезмерным употреблением алкоголя.',
//     },
//     voteVolume: 150,
//   },
//   {
//     voteID: 2,
//     text: {
//       title: 'При розничной продаже алкоголя покупка привязывается к конкретному физическому лицу',
//       content: 'Моя идея заключается в том, чтобы была общероссийская база достигших совершеннолетнего возраста. При розничной продаже алкоголя покупка привязывается к конкретному физическому лицу. В личном кабинете можно посмотреть, сколько алкогольной продукции уже приобретено в этом году, месяце. Невозможно приобрестифы зическому лицу объем спиртного больший допустимого. А норму должны разработать наркологи и другие специалисты здравоохранения. Таким образом, люди, страдающие от алкогольной зависимости, не будут иметь бесконтрольный доступ к спиртным напиткам. Это убережёт будущие поколения от зависимости и заболеваний, которые могут быть вызваны чрезмерным употреблением алкоголя.',
//     },
//     voteVolume: 140,
//   },
//   {
//     voteID: 3,
//     text: {
//       title: 'При розничной продаже алкоголя покупка привязывается к конкретному физическому лицу',
//       content: 'Моя идея заключается в том, чтобы была общероссийская база достигших совершеннолетнего возраста. При розничной продаже алкоголя покупка привязывается к конкретному физическому лицу. В личном кабинете можно посмотреть, сколько алкогольной продукции уже приобретено в этом году, месяце. Невозможно приобрестифы зическому лицу объем спиртного больший допустимого. А норму должны разработать наркологи и другие специалисты здравоохранения. Таким образом, люди, страдающие от алкогольной зависимости, не будут иметь бесконтрольный доступ к спиртным напиткам. Это убережёт будущие поколения от зависимости и заболеваний, которые могут быть вызваны чрезмерным употреблением алкоголя.',
//     },
//     voteVolume: 130,
//   },
// ];

const data = [
  {
    id: 1,
    title: 'Ремонт чего-нибудь',
    question: 'Как вы хотите провести новогодние праздники?',
    text: 'На сайте управления социальной защиты населения размещен банк данных граждан, находящихся в трудной жизненной ситуации и нуждающихся в помощи. Как бы вы хотели узнавать о таких гражданах?',
    votesAll: 500,
    tags: ['Салехард', 'Социальная среда'],
    start: 1858427600000,
    users: [{ name: 'Dffa', surname: 'dasdas', image: '/images/avatar/expert-1.png' }, { name: 'nbvcbvn', surname: 'fsd', image: '/images/avatar/expert-2.png' }, { name: 'czxc', surname: 'bvcj', image: '/images/avatar/expert-3.png' }, { name: 'hdfad', surname: 'czxn' }],
    type: 'voting',
    votesData: votess2,
    documents,
    step: 3,
    status: 'active',
    maxVotes: 1,
  },
  {
    id: 2,
    type: 'voting',
    title: 'благоустройство городской среды',
    ideasAll: 24,
    votesAll: 3,
    peopleAll: 2350,
    tags: ['Лабытнанги', 'Уютный-Ямал'],
    documents: [
      {
        href: '/upload/Описание отдыха.pdf',
      },
      {
        href: '/upload/Фото с отдыха.jpg',
      },
    ],
    question: 'Как привлечь внимание горожан к нуждающимся во внимании горожан жителям Нового Уренгоя?',
    text: 'На сайте управления социальной защиты населения размещен банк данных граждан, находящихся в трудной жизненной ситуации и нуждающихся в помощи. Как бы вы хотели узнавать о таких гражданах?',
    start: 1868427600000,
    users: [{ name: 'Dffa', surname: 'dasdas', image: '/images/avatar/expert-1.png' }, { name: 'nbvcbvn', surname: 'fsd', image: '/images/avatar/expert-2.png' }, { name: 'czxc', surname: 'bvcj', image: '/images/avatar/expert-3.png' }, { name: 'hdfad', surname: 'czxn' }],
    votesData: votess1,
    ideasPicked: votess1.length,
    step: 4,
    status: 'archive',
    maxVotes: 2,
  },
  {
    id: 3,
    type: 'voting',
    status: 'archive',
    title: 'благоустройство городской среды',
    votesAll: 3,
    peopleAll: 2350,
    tags: ['Лабытнанги', 'Уютный-Ямал'],
    documents,
    question: 'Как привлечь внимание горожан к нуждающимся во внимании горожан жителям Нового Уренгоя?',
    text: 'На сайте управления социальной защиты населения размещен банк данных граждан, находящихся в трудной жизненной ситуации и нуждающихся в помощи. Как бы вы хотели узнавать о таких гражданах?',
    start: 1869427600000,
    users: [{ name: 'Dffa', surname: 'dasdas', image: '/images/avatar/expert-1.png' }, { name: 'nbvcbvn', surname: 'fsd', image: '/images/avatar/expert-2.png' }, { name: 'czxc', surname: 'bvcj', image: '/images/avatar/expert-3.png' }, { name: 'hdfad', surname: 'czxn' }],
    votesData: votess1,
    maxVotes: 3,
    step: 3,
  },
  // {
  //   id: 4,
  //   title: 'Ремонт чего-нибудь',
  //   question: 'Как вы хотите провести новогодние праздники? 6',
  //   text: 'На сайте управления социальной защиты населения размещен банк данных граждан, находящихся в трудной жизненной ситуации и нуждающихся в помощи. Как бы вы хотели узнавать о таких гражданах?',
  //   ideasAll: 11,
  //   tags: ['Новый Уренгой', 'Общество'],
  //   tag: 'социальная среда',
  //   start: 1878427600000,
  //   users: [{ name: 'Dffa', surname: 'dasdas' }, { name: 'nbvcbvn', surname: 'fsd' }, { name: 'czxc', surname: 'bvcj' }, { name: 'hdfad', surname: 'czxn' }],
  //   type: 'voting',
  //   documents,
  //   votesData: votess1,
  // },
  // {
  //   id: 5,
  //   title: 'Ремонт чего-нибудь',
  //   question: 'Как вы хотите провести новогодние праздники? 7',
  //   text: 'На сайте управления социальной защиты населения размещен банк данных граждан, находящихся в трудной жизненной ситуации и нуждающихся в помощи. Как бы вы хотели узнавать о таких гражданах?',
  //   ideasAll: 11,
  //   tags: ['Новый Уренгой', 'Общество'],
  //   tag: 'социальная среда',
  //   start: 1879427600000,
  //   users: [{ name: 'Dffa', surname: 'dasdas' }, { name: 'nbvcbvn', surname: 'fsd' }, { name: 'czxc', surname: 'bvcj' }, { name: 'hdfad', surname: 'czxn' }],
  //   type: 'voting',
  //   documents,
  //   votesData: votess2,
  // },
  // {
  //   id: 6,
  //   title: 'Ремонт чего-нибудь',
  //   question: 'Как вы хотите провести новогодние праздники? 8',
  //   votesAll: 3,
  //   peopleAll: 463,
  //   tags: ['Новый Уренгой', 'Общество'],
  //   tag: 'социальная среда',
  //   start: 1888427600000,
  //   users: [{ name: 'Dffa', surname: 'dasdas' }, { name: 'nbvcbvn', surname: 'fsd' }, { name: 'czxc', surname: 'bvcj' }, { name: 'hdfad', surname: 'czxn' }],
  //   type: 'voting',
  //   documents,
  //   votesData: votess3,
  // },
  // {
  //   id: 7,
  //   title: 'Ремонт чего-нибудь',
  //   question: 'Как вы хотите провести новогодние праздники? 9',
  //   text: 'На сайте управления социальной защиты населения размещен банк данных граждан, находящихся в трудной жизненной ситуации и нуждающихся в помощи. Как бы вы хотели узнавать о таких гражданах?',
  //   votesAll: 3,
  //   peopleAll: 430,
  //   tags: ['Новый Уренгой', 'Общество'],
  //   tag: 'социальная среда',
  //   start: 1928427600000,
  //   users: [{ name: 'Dffa', surname: 'dasdas' }, { name: 'nbvcbvn', surname: 'fsd' }, { name: 'czxc', surname: 'bvcj' }, { name: 'hdfad', surname: 'czxn' }],
  //   type: 'voting',
  //   documents,
  //   votesData: votess4,
  // },
  // {
  //   id: 8,
  //   type: 'voting',
  //   status: 'active',
  //   title: 'благоустройство городской среды',
  //   votesAll: 3,
  //   peopleAll: 2350,
  //   tags: ['Новый Уренгой', 'Общественная деятельность'],
  //   images: [],
  //   question: 'Как привлечь внимание горожан к нуждающимся во внимании горожан жителям Нового Уренгоя?',
  //   text: 'На сайте управления социальной защиты населения размещен банк данных граждан, находящихся в трудной жизненной ситуации и нуждающихся в помощи. Как бы вы хотели узнавать о таких гражданах?',
  //   start: 1818427600000,
  //   users: [{ name: 'Dffa', surname: 'dasdas' }, { name: 'nbvcbvn', surname: 'fsd' }, { name: 'czxc', surname: 'bvcj' }, { name: 'hdfad', surname: 'czxn' }],
  //   votesData: votess1,
  //   documents,
  // },
];

export default data;
