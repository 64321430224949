/* eslint-disable react/react-in-jsx-scope */
export const MessagesGradient = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.43 16.37c3.66-.84 6.37-3.91 6.37-7.57 0-4.3-3.76-7.8-8.4-7.8C3.76 1 0 4.5 0 8.8c0 3.92 3.12 7.17 7.17 7.72l.03.08v3l3.23-3.23Z" fill="url(#linearGradient_backgroundBlur_7033_1211)" fillOpacity=".9" />
    <g filter="url(#filter_backgroundBlur_7033_1211)">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.63 19.97c3.66-.84 6.37-3.91 6.37-7.57 0-4.3-3.76-7.8-8.4-7.8-4.64 0-8.4 3.5-8.4 7.8 0 3.92 3.12 7.17 7.17 7.72l.03.08v3l3.23-3.23Z" fill="url(#linearGradient_backgroundBlur_7033_1212)" fillOpacity=".8" />
    </g>
    <rect x="11" y="10" width="8" height="2" rx=".5" fill="#fff" fillOpacity=".6" />
    <rect x="11" y="13" width="6" height="2" rx=".5" fill="#fff" fillOpacity=".6" />
    <rect x="18" y="13" width="3" height="2" rx=".5" fill="#fff" fillOpacity=".6" />
    <defs>
      <linearGradient id="linearGradient_backgroundBlur_7033_1211" x1="61.8" y1="-9.8" x2="-3" y2="8.8" gradientUnits="userSpaceOnUse">
        <stop offset=".68" stopColor="#B392F4" />
        <stop offset="1" stopColor="#EBC2C6" />
      </linearGradient>
      <linearGradient id="linearGradient_backgroundBlur_7033_1212" x1="25.2" y1="4.6" x2="-11.4" y2="11.8" gradientUnits="userSpaceOnUse">
        <stop stopColor="#87D9E6" />
        <stop offset=".33" stopColor="#8994F8" />
        <stop offset=".68" stopColor="#B392F4" />
        <stop offset="1" stopColor="#EBC2C6" />
      </linearGradient>
      <filter id="filter_backgroundBlur_7033_1211" x="4.8" y="2.2" width="21.6" height="23.4" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7033_121" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_7033_121" result="shape" />
      </filter>
    </defs>
  </svg>
);

export const CalendarGradient = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="7" width="16" height="15" rx="2" fill="url(#linearGradient_backgroundBlur_7033_1212_a)" fillOpacity=".8" />
    <g filter="url(#filter_backgroundBlur_7033_1211_b)">
      <rect x="4.01" y="5" width="16" height="15" rx="2" fill="url(#linearGradient_backgroundBlur_7033_1212_c)" fillOpacity=".8" />
    </g>
    <g filter="url(#filter_backgroundBlur_7033_1211_d)" fill="#fff" fillOpacity=".6">
      <rect x="7" y="13" width="2" height="2" rx=".5" />
      <rect x="7" y="16" width="2" height="2" rx=".5" />
      <rect x="10" y="10" width="2" height="2" rx=".5" />
      <rect x="10" y="13" width="2" height="2" rx=".5" />
      <rect x="10" y="16" width="2" height="2" rx=".5" />
      <rect x="13" y="10" width="2" height="2" rx=".5" />
      <rect x="13" y="13" width="2" height="2" rx=".5" />
      <rect x="13" y="16" width="2" height="2" rx=".5" />
      <rect x="16" y="10" width="2" height="2" rx=".5" />
      <rect x="16" y="13" width="2" height="2" rx=".5" />
    </g>
    <g filter="url(#filter_backgroundBlur_7033_1211_e)">
      <path d="M6 4v1h2V4H6Zm3 2.5a1 1 0 0 0 2 0H9ZM8.5 3a1 1 0 0 0 0-2v2ZM8 4v-.5H6V4h2Zm1-.5v3h2v-3H9ZM8.5 3c.28 0 .5.22.5.5h2A2.5 2.5 0 0 0 8.5 1v2Zm-.5.5c0-.28.22-.5.5-.5V1A2.5 2.5 0 0 0 6 3.5h2Z" fill="#9164F0" fillOpacity=".8" />
    </g>
    <g filter="url(#filter_backgroundBlur_7033_1211_f)">
      <path d="M13 4v1h2V4h-2Zm3 2.5a1 1 0 1 0 2 0h-2ZM15.5 3a1 1 0 1 0 0-2v2ZM15 4v-.5h-2V4h2Zm1-.5v3h2v-3h-2Zm-.5-.5c.28 0 .5.22.5.5h2A2.5 2.5 0 0 0 15.5 1v2Zm-.5.5c0-.28.22-.5.5-.5V1A2.5 2.5 0 0 0 13 3.5h2Z" fill="#9164F0" fillOpacity=".8" />
    </g>
    <defs>
      <filter id="filter_backgroundBlur_7033_1211_b" x="3.01" y="4" width="18" height="17" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation=".5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7033_134" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_7033_134" result="shape" />
      </filter>
      <filter id="filter_backgroundBlur_7033_1211_d" x="6" y="9" width="13" height="10" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation=".5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7033_134" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_7033_134" result="shape" />
      </filter>
      <filter id="filter_backgroundBlur_7033_1211_e" x="5" y="0" width="7" height="8.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation=".5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7033_134" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_7033_134" result="shape" />
      </filter>
      <filter id="filter_backgroundBlur_7033_1211_f" x="12" y="0" width="7" height="8.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation=".5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7033_134" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_7033_134" result="shape" />
      </filter>
      <linearGradient id="linearGradient_backgroundBlur_7033_1212_a" x1="37" y1="-5" x2="2.5" y2="20" gradientUnits="userSpaceOnUse">
        <stop stopColor="#87D9E6" />
        <stop offset=".39" stopColor="#8994F8" />
        <stop offset=".68" stopColor="#B392F4" />
        <stop offset="1" stopColor="#EBC2C6" />
      </linearGradient>
      <linearGradient id="linearGradient_backgroundBlur_7033_1212_c" x1="24.01" y1="3" x2="-11.49" y2="25" gradientUnits="userSpaceOnUse">
        <stop stopColor="#87D9E6" />
        <stop offset=".39" stopColor="#8994F8" />
        <stop offset=".68" stopColor="#B392F4" />
        <stop offset="1" stopColor="#EBC2C6" />
      </linearGradient>
    </defs>
  </svg>
);

export const CartGradient = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter_backgroundBlur_7033_1213_a)">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.47 1a2 2 0 0 0-1.97 1.7L13.14 5H2A2 2 0 0 0 .03 7.35l1.41 8A2 2 0 0 0 3.41 17h10.41a2 2 0 0 0 1.97-1.65l1.41-8A2 2 0 0 0 15.23 5h-.06l.3-2h3.65a1 1 0 1 0 0-2h-3.65Z" fill="url(#linearGradient_backgroundBlur_7033_1213_b)" fillOpacity=".7" />
    </g>
    <circle opacity=".8" cx="7" cy="22" r="2" fill="url(#linearGradient_backgroundBlur_7033_1213_c)" />
    <circle opacity=".8" cx="15" cy="22" r="2" fill="url(#linearGradient_backgroundBlur_7033_1213_d)" />
    <g filter="url(#filter_backgroundBlur_7033_1213_e)">
      <path fillRule="evenodd" clipRule="evenodd" d="M20.2 1.3a1 1 0 1 0-1.4 1.4L20.08 4h-1.23a2 2 0 0 0-1.98 1.7l-.2 1.3H4.38a2 2 0 0 0-1.97 2.35l1.42 8A2 2 0 0 0 5.8 19h10.4a2 2 0 0 0 1.97-1.65l1.42-8a2 2 0 0 0-.93-2.06l.2-1.29h3.64a1 1 0 0 0 .7-1.7l-3-3Z" fill="url(#linearGradient_backgroundBlur_7033_1213_f)" fillOpacity=".8" />
    </g>
    <g filter="url(#filter_backgroundBlur_7033_1213_g)">
      <path d="m17 11.5.87-5.65a1 1 0 0 1 .99-.85h3.64l-3-3" stroke="url(#linearGradient_backgroundBlur_7033_1213_h)" strokeOpacity=".8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <g filter="url(#filter_backgroundBlur_7033_1213_i)" fill="#fff" fillOpacity=".6">
      <rect x="6" y="10" width="2" height="2" rx=".5" />
      <rect x="7" y="13" width="2" height="2" rx=".5" />
      <rect x="9" y="10" width="2" height="2" rx=".5" />
      <rect x="10" y="13" width="2" height="2" rx=".5" />
      <rect x="12" y="10" width="2" height="2" rx=".5" />
      <rect x="13" y="13" width="2" height="2" rx=".5" />
    </g>
    <defs>
      <linearGradient id="linearGradient_backgroundBlur_7033_1213_b" x1="76.5" y1="-10.5" x2="4.64" y2="23.56" gradientUnits="userSpaceOnUse">
        <stop stopColor="#87D9E6" />
        <stop offset=".33" stopColor="#8994F8" />
        <stop offset=".68" stopColor="#B392F4" />
        <stop offset="1" stopColor="#EBC2C6" />
      </linearGradient>
      <linearGradient id="linearGradient_backgroundBlur_7033_1213_c" x1="14.5" y1="18.5" x2="3" y2="20" gradientUnits="userSpaceOnUse">
        <stop stopColor="#87D9E6" />
        <stop offset=".33" stopColor="#8994F8" />
        <stop offset=".68" stopColor="#B392F4" />
        <stop offset="1" stopColor="#EBC2C6" />
      </linearGradient>
      <linearGradient id="linearGradient_backgroundBlur_7033_1213_d" x1="17" y1="20" x2="4.5" y2="22" gradientUnits="userSpaceOnUse">
        <stop stopColor="#87D9E6" />
        <stop offset=".33" stopColor="#8994F8" />
        <stop offset=".68" stopColor="#B392F4" />
        <stop offset="1" stopColor="#EBC2C6" />
      </linearGradient>
      <linearGradient id="linearGradient_backgroundBlur_7033_1213_f" x1="-2.5" y1="20.5" x2="22.41" y2="-11.35" gradientUnits="userSpaceOnUse">
        <stop stopColor="#87D9E6" />
        <stop offset=".33" stopColor="#8994F8" />
        <stop offset=".68" stopColor="#B392F4" />
        <stop offset="1" stopColor="#EBC2C6" />
      </linearGradient>
      <linearGradient id="linearGradient_backgroundBlur_7033_1213_h" x1="10.5" y1="20" x2="19.5" y2="-.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#87D9E6" />
        <stop offset=".33" stopColor="#8994F8" />
        <stop offset=".68" stopColor="#B392F4" />
        <stop offset="1" stopColor="#EBC2C6" />
      </linearGradient>
      <filter id="filter_backgroundBlur_7033_1213_a" x="-1" y="0" width="22.12" height="18" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation=".5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7033_154" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_7033_154" result="shape" />
      </filter>
      <filter id="filter_backgroundBlur_7033_1213_e" x="1.38" y="0" width="23.12" height="20" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation=".5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7033_154" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_7033_154" result="shape" />
      </filter>
      <filter id="filter_backgroundBlur_7033_1213_g" x="15" y="0" width="9.5" height="13.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation=".5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7033_154" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_7033_154" result="shape" />
      </filter>
      <filter id="filter_backgroundBlur_7033_1213_i" x="5" y="9" width="11" height="7" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation=".5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7033_154" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_7033_154" result="shape" />
      </filter>
    </defs>
  </svg>
);

export const CardsGradient = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter_backgroundBlur_7033_175_a)">
      <rect x="1" y="2" width="20" height="14" rx="4" fill="url(#linearGradient_backgroundBlur_7033_175_b)" fillOpacity=".8" />
    </g>
    <g filter="url(#filter_backgroundBlur_7033_175_c)">
      <rect x="4" y="8" width="20" height="14" rx="4" fill="url(#linearGradient_backgroundBlur_7033_175_d)" fillOpacity=".8" />
    </g>
    <rect x="8" y="14" width="9" height="2" rx=".5" fill="#fff" fillOpacity=".6" />
    <rect x="8" y="11" width="6" height="2" rx=".5" fill="#fff" fillOpacity=".6" />
    <rect x="8" y="17" width="7" height="2" rx=".5" fill="#fff" fillOpacity=".6" />
    <defs>
      <linearGradient id="linearGradient_backgroundBlur_7033_175_b" x1="63" y1="-20.56" x2="8.01" y2="29.69" gradientUnits="userSpaceOnUse">
        <stop stopColor="#87D9E6" />
        <stop offset=".39" stopColor="#8994F8" />
        <stop offset=".68" stopColor="#B392F4" />
        <stop offset="1" stopColor="#EBC2C6" />
      </linearGradient>
      <linearGradient id="linearGradient_backgroundBlur_7033_175_d" x1="29" y1="6.13" x2="-7.37" y2="36.32" gradientUnits="userSpaceOnUse">
        <stop stopColor="#87D9E6" />
        <stop offset=".39" stopColor="#8994F8" />
        <stop offset=".68" stopColor="#B392F4" />
        <stop offset="1" stopColor="#EBC2C6" />
      </linearGradient>
      <filter id="filter_backgroundBlur_7033_175_a" x="0" y="1" width="22" height="16" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation=".5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7033_175" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_7033_175" result="shape" />
      </filter>
      <filter id="filter_backgroundBlur_7033_175_c" x="3" y="7" width="22" height="16" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation=".5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7033_175" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_7033_175" result="shape" />
      </filter>
    </defs>
  </svg>
);

export const ProfileGradient = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 3a4.58 4.58 0 0 0-4.56 4.6c0 2.53 2.03 4.6 4.56 4.6a4.58 4.58 0 0 0 4.56-4.6C16.56 5.07 14.53 3 12 3ZM9.44 7.6C9.44 6.15 10.6 5 12 5c1.4 0 2.56 1.15 2.56 2.6 0 1.45-1.16 2.6-2.56 2.6a2.58 2.58 0 0 1-2.56-2.6Z" fill="url(#linearGradient_backgroundBlur_7033_177_a)" />
    <path d="M14.1 11.69a4.59 4.59 0 0 0 1.63-1.45A8.1 8.1 0 0 1 20 17.37 3.63 3.63 0 0 1 16.4 21H7.6A3.63 3.63 0 0 1 4 17.37a8.1 8.1 0 0 1 4.27-7.13c.42.6.98 1.1 1.64 1.45A6.1 6.1 0 0 0 6 17.37c0 .9.74 1.63 1.6 1.63h8.8c.86 0 1.6-.72 1.6-1.63a6.1 6.1 0 0 0-3.9-5.68Z" fill="url(#linearGradient_backgroundBlur_7033_177_b)" />
    <defs>
      <linearGradient id="linearGradient_backgroundBlur_7033_177_a" x1="20" y1="3" x2="4" y2="3" gradientUnits="userSpaceOnUse">
        <stop stopColor="#87D9E6" />
        <stop offset=".33" stopColor="#8994F8" />
        <stop offset=".68" stopColor="#B392F4" />
        <stop offset="1" stopColor="#EBC2C6" />
      </linearGradient>
      <linearGradient id="linearGradient_backgroundBlur_7033_177_b" x1="20" y1="3" x2="4" y2="3" gradientUnits="userSpaceOnUse">
        <stop stopColor="#87D9E6" />
        <stop offset=".33" stopColor="#8994F8" />
        <stop offset=".68" stopColor="#B392F4" />
        <stop offset="1" stopColor="#EBC2C6" />
      </linearGradient>
    </defs>
  </svg>
);
