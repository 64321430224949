import React from 'react';
import CookiesBanner from './CookiesBanner';
import styles from './WarningBanners.module.scss';

const WarningBanners = () => (
  <div className={styles.wrapper}>
    <CookiesBanner />
  </div>
);

export default WarningBanners;
