export default [
  {
    id: '1',
    header: 'Уютный Ямал',
    subtitle: 'Идеи принимаются с 01 мая',
    description: 'У вас достаточно времени обдумать и внести свое предложение или обсудить чьи-то не менее замечательные идеи',
    project_type: 'yamal',
    external_link: null,
    is_background_contrast: 0,
    is_active: 1,
    background_landscape: 'background_small-v1.png',
    background_portrait: 'background_small-v1.png',
    related_main_id: 41,
    is_complete: 0,
    priority: 5,
    created_at: '2022-02-08T10:44:49+05:00',
    updated_at: '2023-05-03T09:25:48+05:00',
  },
  {
    id: '11',
    header: 'Ямальская неделя зимних видов спорта',
    subtitle: '25 февраля - 05 марта 2023г.',
    description: 'Смотри! Участвуй!',
    project_type: 'wintersports',
    external_link: 'https://ямалзаспорт.рф/',
    is_background_contrast: 0,
    is_active: 0,
    background_landscape: 'background_small-v1.1.png',
    background_portrait: 'background_small-v1.1.png',
    related_main_id: null,
    is_complete: 0,
    priority: 5,
    created_at: '2023-02-22T16:04:43+05:00',
    updated_at: '2023-03-14T15:23:07+05:00',
  },
  {
    id: '2',
    header: 'Ёлка Заботы',
    subtitle: 'Благотворительная акция помощи детям Ямала',
    description: null,
    project_type: 'care-tree',
    external_link: null,
    is_background_contrast: 0,
    is_active: 0,
    background_landscape: 'background_small-v2.png',
    background_portrait: 'background_small-v2.png',
    related_main_id: null,
    is_complete: 0,
    priority: 10,
    created_at: '2022-02-08T10:44:49+05:00',
    updated_at: '2023-01-09T15:20:50+05:00',
  },
  {
    id: '6',
    header: 'Питомцы Ямала',
    subtitle: 'Выбери бездомное животное из приюта Ямала',
    description: null,
    project_type: 'dog-and-cat',
    external_link: 'https://питомцы.живёмнасевере.рф/',
    is_background_contrast: 0,
    is_active: 1,
    background_landscape: 'background_small-v3.png',
    background_portrait: 'background_small-v3.png',
    related_main_id: null,
    is_complete: 0,
    priority: 20,
    created_at: '2022-08-12T15:05:25+05:00',
    updated_at: '2022-11-29T09:09:57+05:00',
  },
  {
    id: '7',
    header: 'Безопасный Интернет? Тебе решать!',
    subtitle: 'Наша цель - создание безопасной среды в интернете',
    description: null,
    project_type: 'kiber-druzina',
    external_link: 'https://xn--80aaabpfimeotkl2bb2c0n.xn--p1ai/',
    contrast: 1,
    is_active: 1,
    background_landscape: 'background_small-v1contrast.png',
    background_portrait: 'background_small-v1contrast.png',
    related_main_id: null,
    is_complete: 0,
    priority: 20,
    created_at: '2022-09-07T08:08:43+05:00',
    updated_at: '2023-03-15T10:00:35+05:00',
  },
  {
    id: '8',
    header: 'Год экологии',
    subtitle: 'Отбор лучших проектов',
    description: null,
    project_type: 'year-of-ecology',
    external_link: 'https://годэкологиинаямале.рф',
    contrast: 1,
    is_active: 0,
    background_landscape: 'actual-event-card.jpg',
    background_portrait: 'actual-event-card.jpg',
    related_main_id: null,
    is_complete: 0,
    priority: 70,
    created_at: '2022-11-09T08:16:08+05:00',
    updated_at: '2023-01-17T13:45:11+05:00',
  },
  {
    id: '9',
    header: 'СЕВЕРЯНЕ',
    subtitle: 'Региональный проект',
    description: 'Как быть готовым к подвигу и записаться на курсы начальной военной подготовки.',
    project_type: 'northerners',
    external_link: 'https://северяне.жизньгероя.рф',
    contrast: 1,
    is_active: 1,
    background_landscape: 'event-card.jpg',
    background_portrait: 'event-card.jpg',
    related_main_id: null,
    is_complete: 0,
    priority: 60,
    created_at: '2023-01-16T09:19:02+05:00',
    updated_at: '2023-01-16T09:29:58+05:00',
  },
  {
    id: '12',
    header: 'ВЦИОМ',
    subtitle: 'Cоциологические исследования',
    description: 'Прими участие в опросах о жизни на Ямале',
    project_type: 'sociologic',
    external_link: 'https://sst.gl/yanao',
    is_background_contrast: 0,
    is_active: 1,
    background_landscape: 'https://xn--80adblbabq1bk1bi8r.xn--p1ai/storage/project-landscape/2023-03-15/b435075f3e92e54bc86f9b107f124acc/photo_2023-03-15_11-57-49.jpg',
    background_portrait: 'https://xn--80adblbabq1bk1bi8r.xn--p1ai/storage/project-portrait/2023-03-15/d16d0f94756e934cab704e7d53a9c9a1/photo_2023-03-15_11-57-49.jpg',
    related_main_id: null,
    is_complete: 0,
    priority: 25,
    created_at: '2023-03-15T09:54:51+05:00',
    updated_at: '2023-03-15T09:59:14+05:00',
  },
  {
    id: '13',
    header: 'Гранты от губернатора Ямала',
    subtitle: 'Конкурсы для некоммерческих организаций',
    description: null,
    project_type: 'grants',
    external_link: 'https://xn--80aaaj6begwu4gyb.xn--p1ai/granty-yamala',
    is_background_contrast: 0,
    is_active: 1,
    background_landscape: 'https://xn--80adblbabq1bk1bi8r.xn--p1ai/storage/project-landscape/2023-04-04/e1374c81fdecf6031dccc1768c228cf5/гранты.png',
    background_portrait: 'https://xn--80adblbabq1bk1bi8r.xn--p1ai/storage/project-portrait/2023-04-04/54b85272000d85dcb0c4beb957b7caeb/гранты.png',
    related_main_id: null,
    is_complete: 0,
    priority: 40,
    created_at: '2023-04-04T10:56:55+05:00',
    updated_at: '2023-04-04T11:27:14+05:00',
  },
  {
    id: '3',
    header: 'ВИКТОРИНА',
    subtitle: null,
    description: null,
    project_type: 'quiz',
    external_link: null,
    is_background_contrast: 0,
    is_active: 0,
    background_landscape: null,
    background_portrait: null,
    related_main_id: 3,
    is_complete: 0,
    priority: 50,
    created_at: '2022-02-08T10:44:49+05:00',
    updated_at: '2023-01-16T09:05:07+05:00',
  },
  {
    id: '4',
    header: 'Чистый Ямал',
    subtitle: 'Голосование и викторина проходит с 29 по 31 марта',
    description: 'Проект гражданских инициатив в сфере экологии и охраны природы',
    project_type: 'clean-yamal',
    external_link: null,
    is_background_contrast: 0,
    is_active: 0,
    background_landscape: 'https://xn--80adblbabq1bk1bi8r.xn--p1ai/storage/project-landscape/2022-03-29/c9d4c05b0bd073a70fa488aeee68af4d/banner-clean.png',
    background_portrait: 'https://xn--80adblbabq1bk1bi8r.xn--p1ai/storage/project-portrait/2022-04-28/3e6df1ba2285569d3e712ae70e8819ea/banner-clean.png',
    related_main_id: null,
    is_complete: 0,
    priority: 50,
    created_at: '2022-03-28T10:05:25+05:00',
    updated_at: '2022-05-01T11:15:10+05:00',
  },
  {
    id: '5',
    header: 'ЯПРИВИТ',
    subtitle: 'Вакцинация от гриппа',
    description: 'Удобно. Безопасно. Надежно.',
    project_type: 'ya-privit',
    external_link: 'https://xn--b1albzfp9f.xn--80adblbabq1bk1bi8r.xn--p1ai/',
    is_background_contrast: 0,
    is_active: 1,
    background_landscape: 'https://xn--80adblbabq1bk1bi8r.xn--p1ai/storage/project-landscape/2022-06-23/3a8d1728f0c3829bd005c71bd202b8df/bg_privit.png',
    background_portrait: 'https://xn--80adblbabq1bk1bi8r.xn--p1ai/storage/project-portrait/2022-06-23/e21e9b7535c17e12f14f7fe7ef9859f4/bg_privit.png',
    related_main_id: null,
    is_complete: 0,
    priority: 70,
    created_at: '2022-06-23T08:29:36+05:00',
    updated_at: '2023-04-04T11:27:22+05:00',
  },
];
