import { createSlice } from '@reduxjs/toolkit';

export const StepType = {
  VOTE: 'VOTE',
  QUESTIONS: 'QUESTIONS',
  PROFILE: 'PROFILE',
  LOCATIONS: 'LOCATIONS',
  HOMEPROFILE: 'HOMEPROFILE',
  WINNERS: 'WINNERS',
};

const quizSlice = createSlice({
  name: 'quiz',
  initialState: {
    step: StepType.VOTE,
    questNumber: 0,
    completeQuestion: [],
  },
  reducers: {
    setQuestNumber: (state, action) => {
      state.questNumber = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setCompleteQuestion: (state, action) => {
      state.completeQuestion = action.payload;
    },
    clearCompleteQuestionAction: (state) => {
      state.completeQuestion = [];
    },
  },
});

export const getStep = (state) => state.step;
export const getQuestNumber = (state) => state.questNumber;
export const getCompleteQuestion = (state) => state.completeQuestion;

export const {
  setStep,
  setQuestNumber,
  setCompleteQuestion,
  clearCompleteQuestionAction,
} = quizSlice.actions;

export default quizSlice.reducer;
